import React, { Component } from "react";
import Navbar from "../components/Nav.jsx";
import Chart from "chart.js";
import ChartDataLabels from "chartjs-plugin-datalabels";

import ImageUpload from "../components/ImageUpload";
import socketIOClient from "socket.io-client";
import constant from "../../utils/constants";
import PdfGenerator from "../components/PdfGenerator";
import GetNames from "../components/GetNames.jsx";
import config from "../../config";
import FormData from "form-data";
// import html2canvas from 'html2canvas'
import { ToastContainer, toast } from "react-toastify";

const axios = require("axios").default;
class CostReport extends Component {
  constructor(props) {
    super(props);
    //  this.client = new useApolloClient();
    this.state = {
      image: true,
      formFields: null,
      technologyName: "",
      socketObj: {},
      currencySymbol: "",
      downloadTypeValue: 0,
      downloadPdf: false,
      serverImageObj: {},
      // disableInputs: false,
      defaultCostImageUrl: "",
      donutImage: "",
      report_name: "",
      isLoading: false,
    };
    console.log("this.props", this.props);
  }
  async componentDidMount() {
    console.log('this.state.formFields', this.state.formFields);

    console.log("this.props", this.props.location.search);
    console.log("this.props", parseInt(this.props.location.search.slice(4)));

    // check url: to check where user is coming from.
    // const tempCheck = parseInt(this.props.location.search.slice(4)) / 2
    const id = this.props.location.search.slice(4);
    console.log("(typeof tempCheck)", typeof tempCheck);
    let currencies = JSON.parse(sessionStorage.getItem("all_currencies"));

    if (this.isNumber(id)) {
      let reportObj = {};
      this.getReportData(id).then((obj) => {
        // this.setState({ disableInputs: true })
        console.log(
          "JSON.parse(obj.data.data.report_data)",
          JSON.parse(obj.data.data.report_data)
        );
        reportObj = JSON.parse(obj.data.data.report_data);
        sessionStorage.setItem("calculator_values", obj.data.data.report_data);
        const region_id = reportObj.region_id.value;
        console.log("region_id, currencyName", region_id);
        const currency = currencies.find((x) => x.region_id === region_id);
        sessionStorage.setItem("currency", currency.name);
        console.log("currency.currency_symbol", currency);
        // this.setState({ defaultCostImageUrl: obj.data.data.image_url })
        // this.setState({ image: config.serviceUrl + obj.data.data.image_url })
        this.setState({ currencySymbol: currency.currency_symbol });
        this.setState({ formFields: reportObj });
      });
      const socket = socketIOClient(constant.uri);
      const patch = require("socketio-wildcard")(socketIOClient.Manager);
      patch(socket);
      this.setState({ socketObj: socket });

      socket.on("*", (event, data) => {
        console.log(event, "event", data, data);
        if (event.data[0] === "FO-Part-Weight2") {
          const formFields = this.state.formFields;
          formFields["part_weight"] = {
            value: parseFloat(event.data[1].data?.value) / 1000,
            override: false,
          };
          this.setState({ formFields: formFields });
          this.total();
        }
        if (event.data[0] === "FO-Data-Prep") {
          const formFields = this.state.formFields;
          formFields["data_preparation_cost_per_part"] = {
            value: parseFloat(event.data[1].data?.value),
            override: false,
          };
          this.setState({ formFields: formFields });
          this.total();
        }
        if (
          event.data[0] === "FO-AM-System-H-Job" ||
          event.data[0] === "FO-AM-System-H-Job2"
        ) {
          const formFields = this.state.formFields;
          formFields["am_system_h_job"] = {
            value: parseFloat(event.data[1].data)?.value,
            override: false,
          };
          this.setState({ formFields: formFields });
          this.state.socketObj.emit("calculator", {
            key: "FO-AM-System-Cost-Part",
            input: {
              system_cost_per_hour:
                this.state.formFields["system_cost_per_hour"].value,
              parts_per_job:
                this.state.formFields &&
                  this.state.formFields["parts_per_job"]
                  ? this.state.formFields["parts_per_job"].value
                  : this.state.formFields["total_no_of_parts"].value,
              am_system_h_job: formFields["am_system_h_job"].value,
            },
          });
        }
        if (event.data[0] === "FO-AM-System-Cost-Part") {
          const formFields = this.state.formFields;
          formFields["am_system_cost_per_part"] = {
            value: parseFloat(event.data[1].data?.value),
            override: false,
          };
          this.setState({ formFields: formFields });
          this.total();
        }
        if (event.data[0] === "FO-Manual-Labor-H-Job") {
          const formFields = this.state.formFields;
          formFields["manual_labor_h_per_job"] = {
            value: parseFloat(event.data[1].data?.value),
            override: false,
          };
          this.setState({ formFields: formFields });
          this.total();
        }
        if (event.data[0] === "FO-Manual-Labor-Cost-Part") {
          const formFields = this.state.formFields;
          formFields["manual_labor_cost_per_part"] = {
            value: parseFloat(event.data[1].data?.value),
            override: false,
          };
          this.setState({ formFields: formFields });
          this.total();
        }
        if (event.data[0] === "FO-Oper-Expense-Cost-Part") {
          const formFields = this.state.formFields;
          formFields["operating_expenses_cost_per_part"] = {
            value: parseFloat(event.data[1].data)?.value,
            override: false,
          };
          this.setState({ formFields: formFields });
          this.total();
        }
        if (
          event.data[0] === "FO-Mater-Cost-Kg-Job" ||
          event.data[0] === "FO-Mater-Cost-Kg-Job2"
        ) {
          const formFields = this.state.formFields;
          formFields["material_cost_kg_per_job"] = {
            value: parseFloat(event.data[1].data?.value),
            override: false,
          };
          this.setState({ formFields: formFields });
          this.state.socketObj.emit("calculator", {
            key: "FO-Mater-Cost-Part",
            input: {
              material_price: this.state.formFields["material_price"].value,
              parts_per_job:
                this.state.formFields &&
                  this.state.formFields["parts_per_job"]
                  ? this.state.formFields["parts_per_job"].value
                  : this.state.formFields["total_no_of_parts"].value,
              material_cost_kg_per_job:
                formFields["material_cost_kg_per_job"].value,
            },
          });
        }
        if (event.data[0] === "FO-Mater-Cost-Part") {
          const formFields = this.state.formFields;
          formFields["material_cost_per_part"] = {
            value: parseFloat(event.data[1].data?.value),
            override: false,
          };
          this.setState({ formFields: formFields });
          this.total();
        }
        if (event.data[0] === "FO-Powd-Cost-Part") {
          const formFields = this.state.formFields;
          formFields["powder_removal_cost_per_part"] = {
            value: parseFloat(event.data[1].data?.value),
            override: false,
          };
          this.setState({ formFields: formFields });
          this.total();
        }
        if (event.data[0] === "FO-Post-Process-Cost-Part") {
          const formFields = this.state.formFields;
          formFields["post_processing_cost_per_part"] = {
            value: parseFloat(event.data[1].data?.value),
            override: false,
          };
          this.setState({ formFields: formFields });
          this.total();
        }
        if (event.data[0] === "FO-Laboratory-Cost-Part") {
          const formFields = this.state.formFields;
          formFields["laboratory_cost_per_part"] = {
            value: parseFloat(event.data[1].data?.value),
            override: false,
          };
          this.setState({ formFields: formFields });
          this.total();
        }
        if (event.data[0] === "FO-Overhead-Cost-Part") {
          const formFields = this.state.formFields;
          formFields["overhead_cost_per_part"] = {
            value: parseFloat(event.data[1].data?.value),
            override: false,
          };
          this.setState({ formFields: formFields });
          this.total();
        }
        if (event.data[0] === "FO-Total-PPP") {
          const formFields = this.state.formFields;
          formFields["total_cost_per_part"] = {
            value: parseFloat(event.data[1].data?.value),
            override: false,
          };
          this.setState({ formFields: formFields });
          // this.total();
        }
        if (event.data[0] === "FO-Sale-Price") {
          const formFields = this.state.formFields;
          formFields["sale_price"] = {
            value: parseFloat(event.data[1].data?.value),
            override: false,
          };
          this.setState({ formFields: formFields });
          this.total();
        }
        if (
          event.data[0] !== "FO-Total-PPP" &&
          this.state.formFields["overhead_cost_per_part"] &&
          this.state.formFields["material_cost_per_part"] &&
          this.state.formFields["powder_removal_cost_per_part"] &&
          this.state.formFields["laboratory_cost_per_part"] &&
          this.state.formFields["post_processing_cost_per_part"]
        ) {

          this.total();
        }

        var d2 = document.getElementById("d2");
        if (window.reportDognutChart) {
          window.reportDognutChart.destroy();
        }
        window.reportDognutChart = new Chart(d2, {
          plugins: [ChartDataLabels],
          type: "doughnut",

          data: {
            datasets: [
              {
                data: [
                  (this.state.formFields &&
                    this.state.formFields["post_processing_cost_per_part"] !==
                    undefined &&
                    this.state.formFields["total_cost_per_part"] !== undefined
                    ? parseFloat(
                      this.state.formFields[
                        "post_processing_cost_per_part"
                      ].value
                    ) /
                    parseFloat(
                      this.state.formFields["total_cost_per_part"].value
                    )
                    : 0) * 100,
                  (this.state.formFields &&
                    this.state.formFields["system_cost_per_part"] !==
                    undefined &&
                    this.state.formFields["total_cost_per_part"] !== undefined
                    ? parseFloat(
                      this.state.formFields["system_cost_per_part"].value
                    ) /
                    parseFloat(
                      this.state.formFields["total_cost_per_part"].value
                    )
                    : 0) * 100,
                  (this.state.formFields &&
                    this.state.formFields["material_cost_per_part"] !==
                    undefined &&
                    this.state.formFields["total_cost_per_part"] !== undefined
                    ? parseFloat(
                      this.state.formFields["material_cost_per_part"]
                        .value
                    ) /
                    parseFloat(
                      this.state.formFields["total_cost_per_part"].value
                    )
                    : 0) * 100,
                ],
                backgroundColor: ["#7761cf", "#fcc342", "#00bff3"],
                borderWidth: 0,
              },
            ],
            borderWidth: 0,
            // These labels appear in the legend and in the tooltips when hovering different arcs

            labels: [
              "Post Processing",
              "System Cost Per Part",
              "Material Cost Per Part",
            ],
          },
          options: {
            responsive: true,
            maintainAspectRatio: false,
            cutoutPercentage: 50,
            rotation: 1.5 * Math.PI,
            circumference: 2 * Math.PI,
            legend: {
              display: false,
              borderWidth: 0,
              position: "bottom",
              labels: {
                fontColor: "#c9cbcd",
                fontFamily: "'Montserrat', sans-serif",
                fontWeight: 600,
                fontSize: 10,
                padding: 30,
              },
            },
            tooltips: {
              enabled: false,
            },
            plugins: {
              datalabels: {
                formatter: (value, ctx) => {
                  let sum = 0;
                  let dataArr = ctx.chart.data.datasets[0].data;
                  // eslint-disable-next-line
                  dataArr.map((data) => {
                    sum += data;
                  });
                  let percentage = ((value * 100) / sum).toFixed(0) + "%";
                  return percentage;
                },
                color: "#ffffff",
                font: {
                  family: "Open Sans",
                  size: 15,
                  weight: 400,
                },
              },
            },
          },
        });

        setTimeout(() => {
          var url_base64jp = document
            .getElementById("d2")
            .toDataURL("image/jpg");
          console.log("URL", url_base64jp);
          this.setState({
            donutImage: url_base64jp,
          });
        }, 2000);
      });
    } else {
      const currency = sessionStorage.getItem("currency");

      this.setState({
        currencySymbol: currencies.find((x) => x.name === currency)
          .currency_symbol,
      });

      // If page is myReports then user want to see the report data
      // disable all inputs and populate data there so that user can see
      // disable save report button
      const socket = socketIOClient(constant.uri);
      const patch = require("socketio-wildcard")(socketIOClient.Manager);
      patch(socket);
      this.setState({ socketObj: socket });
      // this.setState({ clientObj: useApolloClient() });

      const scenario_name = sessionStorage.getItem("scenario_name");
      let calculatorValues;
      if (scenario_name === "default") {
        calculatorValues = JSON.parse(
          sessionStorage.getItem("calculator_values_default")
        );
      } else {
        let wholeReportValuesWithScenarios = JSON.parse(
          sessionStorage.getItem(`calculator_values_${scenario_name}`)
        );
        calculatorValues = wholeReportValuesWithScenarios;
      }

      this.setState(
        {
          formFields: calculatorValues,
          technologyName: sessionStorage.getItem("technologyName"),
        },
        () => {
          // Part Weight Formula for both Metal Estimation and Metal Simulation
          // step_type_id, part_volume, part_support_volume, material_density
          // fromFields['machine_id']
          // fetch GET_NAMES api and integrate it

          console.log(this.props);
          setTimeout(() => {
            const values = JSON.parse(localStorage.getItem("getNames"));

            if (values) {
              const fF = this.state.formFields;
              fF["machine_name"] = values["machine_name"];
              fF["material_name"] = values["material_name"];
              fF["parameter_name_value"] = values["parameter_name"];
              fF["scrape_rate"] = { value: 0 };
              this.setState(
                {
                  formFields: fF,
                },
                (data) => {
                  console.log(data);
                }
              );
            }
          }, 2000);

          // Part Weight Formula End
          console.log("formFields", this.state.formFields);
          this.state.socketObj.emit("calculator", {
            key: "FO-Part-Weight2",
            input: {
              step_type_id: this.state.formFields["step_type_id"]
                ? this.state.formFields["step_type_id"].value
                : 0,
              part_volume: this.state.formFields["part_volume"]
                ? this.state.formFields["part_volume"].value
                : 0,
              part_support_volume:
                this.state.formFields &&
                  this.state.formFields["part_support_volume"]
                  ? this.state.formFields["part_support_volume"].value
                  : 1,
              material_density: this.state.formFields["material_density"]
                ? this.state.formFields["material_density"].value
                : 0,
            },
          });
          this.state.socketObj.emit("calculator", {
            key: "FO-Manual-Labor-H-Job",
            input: {
              setup_time: this.state.formFields["setup_time"]
                ? this.state.formFields["setup_time"].value
                : 0,
              unpacking_time_per_job: this.state.formFields[
                "unpacking_time_per_job"
              ]
                ? this.state.formFields["unpacking_time_per_job"].value
                : 0,
            },
          });
          if (
            sessionStorage.getItem("technologyName") === "Metal (Estimation)"
          ) {
            this.state.socketObj.emit("calculator", {
              key: "FO-Mater-Cost-Kg-Job",
              input: {
                total_part_volume: this.state.formFields["total_part_volume"]
                  ? this.state.formFields["total_part_volume"].value
                  : 0,
                support_factor: this.state.formFields["support_factor"]
                  ? this.state.formFields["support_factor"].value
                  : 0,
                additional_material_losses: this.state.formFields[
                  "additional_material_losses"
                ]
                  ? this.state.formFields["additional_material_losses"].value
                  : 0,
                material_density: this.state.formFields["material_density"]
                  ? this.state.formFields["material_density"].value
                  : 0,
                step_type_id: this.state.formFields["step_type_id"]
                  ? this.state.formFields["step_type_id"].value
                  : 0,
              },
            });
          }
          if (
            sessionStorage.getItem("technologyName") === "Metal (Simulation)"
          ) {
            this.state.socketObj.emit("calculator", {
              key: "FO-Mater-Cost-Kg-Job2",
              input: {
                total_part_volume: this.state.formFields["total_part_volume"]
                  ? this.state.formFields["total_part_volume"].value
                  : 0,
                additional_material_losses: this.state.formFields[
                  "additional_material_losses"
                ]
                  ? this.state.formFields["additional_material_losses"].value
                  : 0,
                material_density: this.state.formFields["material_density"]
                  ? this.state.formFields["material_density"].value
                  : 0,
                step_type_id: this.state.formFields["step_type_id"]
                  ? this.state.formFields["step_type_id"].value
                  : 0,
              },
            });
          }
          if (
            sessionStorage.getItem("technologyName") ===
            "Polymer (Estimation)" ||
            sessionStorage.getItem("technologyName") === "Polymer (Simulation)"
          ) {
            const formFields = this.state.formFields;
            formFields["material_cost_kg_per_job"] = {
              value: formFields["material_used"].value,
              override: false,
            };
            this.setState({ formFields: formFields });
            this.state.socketObj.emit("calculator", {
              key: "FO-Mater-Cost-Part",
              input: {
                material_price: this.state.formFields["material_price"]
                  ? this.state.formFields["material_price"].value
                  : 0,
                parts_per_job:
                  this.state.formFields &&
                    this.state.formFields["parts_per_job"]
                    ? this.state.formFields["parts_per_job"].value
                    : this.state.formFields["total_no_of_parts"].value,
                material_cost_kg_per_job:
                  formFields["material_cost_kg_per_job"].value,
              },
            });
          }
          if (
            sessionStorage.getItem("technologyName") &&
            sessionStorage.getItem("technologyName").split(" ")[0] === "Polymer"
          ) {
            this.state.socketObj.emit("calculator", {
              key: "FO-AM-System-H-Job2",
              input: {
                build_time: this.state.formFields["build_time"].value,
                setup_time: this.state.formFields["setup_time"].value,
                unpacking_time_per_job:
                  this.state.formFields["unpacking_time_per_job"].value,
                cool_down_time_in_system:
                  this.state.formFields["cool_down_time_in_system"].value,
              },
            });
          } else {
            this.state.socketObj.emit("calculator", {
              key: "FO-AM-System-H-Job",
              input: {
                build_time: parseFloat(
                  this.state.formFields["build_time"].value
                ),
                setup_time: parseFloat(
                  this.state.formFields["setup_time"].value
                ),
                unpacking_time_per_job: parseFloat(
                  this.state.formFields["unpacking_time_per_job"].value
                ),
              },
            });
          }
          this.state.socketObj.on("*", (event, data) => {
            // debugger
            console.log(event, "event");
            if (event.data[0] === "FO-Part-Weight2") {
              const formFields = this.state.formFields;
              formFields["part_weight"] = {
                value: parseFloat(event.data[1].data?.value) / 1000,
                override: false,
              };
              this.setState({ formFields: formFields });
              this.total();
            }
            if (event.data[0] === "FO-Data-Prep") {
              const formFields = this.state.formFields;
              formFields["data_preparation_cost_per_part"] = {
                value: parseFloat(event.data[1].data?.value),
                override: false,
              };
              this.setState({ formFields: formFields });
              this.total();
            }
            if (
              event.data[0] === "FO-AM-System-H-Job" ||
              event.data[0] === "FO-AM-System-H-Job2"
            ) {
              const formFields = this.state.formFields;
              formFields["am_system_h_job"] = {
                value: parseFloat(event.data[1].data?.value),
                override: false,
              };
              this.setState({ formFields: formFields });
              this.state.socketObj.emit("calculator", {
                key: "FO-AM-System-Cost-Part",
                input: {
                  system_cost_per_hour:
                    this.state.formFields["system_cost_per_hour"].value,
                  parts_per_job:
                    this.state.formFields &&
                      this.state.formFields["parts_per_job"]
                      ? this.state.formFields["parts_per_job"].value
                      : this.state.formFields["total_no_of_parts"].value,
                  am_system_h_job: formFields["am_system_h_job"].value,
                },
              });
            }
            if (event.data[0] === "FO-AM-System-Cost-Part") {
              const formFields = this.state.formFields;
              formFields["am_system_cost_per_part"] = {
                value: parseFloat(event.data[1].data?.value),
                override: false,
              };
              this.setState({ formFields: formFields });
              this.total();
            }
            if (event.data[0] === "FO-Manual-Labor-H-Job") {
              const formFields = this.state.formFields;
              formFields["manual_labor_h_per_job"] = {
                value: parseFloat(event.data[1].data?.value),
                override: false,
              };
              this.setState({ formFields: formFields });
              this.total();
            }
            if (event.data[0] === "FO-Manual-Labor-Cost-Part") {
              const formFields = this.state.formFields;
              formFields["manual_labor_cost_per_part"] = {
                value: parseFloat(event.data[1].data?.value),
                override: false,
              };
              this.setState({ formFields: formFields });
              this.total();
            }
            if (event.data[0] === "FO-Oper-Expense-Cost-Part") {
              const formFields = this.state.formFields;
              formFields["operating_expenses_cost_per_part"] = {
                value: parseFloat(event.data[1].data?.value),
                override: false,
              };
              this.setState({ formFields: formFields });
              this.total();
            }
            if (
              event.data[0] === "FO-Mater-Cost-Kg-Job" ||
              event.data[0] === "FO-Mater-Cost-Kg-Job2"
            ) {
              const formFields = this.state.formFields;
              formFields["material_cost_kg_per_job"] = {
                value: parseFloat(event.data[1].data?.value),
                override: false,
              };
              this.setState({ formFields: formFields });
              this.state.socketObj.emit("calculator", {
                key: "FO-Mater-Cost-Part",
                input: {
                  material_price: this.state.formFields["material_price"].value,
                  parts_per_job:
                    this.state.formFields &&
                      this.state.formFields["parts_per_job"]
                      ? this.state.formFields["parts_per_job"].value
                      : this.state.formFields["total_no_of_parts"].value,
                  material_cost_kg_per_job:
                    formFields["material_cost_kg_per_job"].value,
                },
              });
            }
            if (event.data[0] === "FO-Mater-Cost-Part") {
              const formFields = this.state.formFields;
              formFields["material_cost_per_part"] = {
                value: parseFloat(event.data[1].data?.value),
                override: false,
              };
              this.setState({ formFields: formFields });
              this.total();
            }
            if (event.data[0] === "FO-Powd-Cost-Part") {
              const formFields = this.state.formFields;
              formFields["powder_removal_cost_per_part"] = {
                value: parseFloat(event.data[1].data?.value),
                override: false,
              };
              this.setState({ formFields: formFields });
              this.total();
            }
            if (event.data[0] === "FO-Post-Process-Cost-Part") {
              const formFields = this.state.formFields;
              formFields["post_processing_cost_per_part"] = {
                value: parseFloat(event.data[1].data?.value),
                override: false,
              };
              this.setState({ formFields: formFields });
              this.total();
            }
            if (event.data[0] === "FO-Laboratory-Cost-Part") {
              const formFields = this.state.formFields;
              formFields["laboratory_cost_per_part"] = {
                value: parseFloat(event.data[1].data?.value),
                override: false,
              };
              this.setState({ formFields: formFields });
              this.total();
            }
            if (event.data[0] === "FO-Overhead-Cost-Part") {
              const formFields = this.state.formFields;
              formFields["overhead_cost_per_part"] = {
                value: parseFloat(event.data[1].data?.value),
                override: false,
              };
              this.setState({ formFields: formFields });
              this.total();
            }
            if (event.data[0] === "FO-Total-PPP") {
              const formFields = this.state.formFields;
              formFields["total_cost_per_part"] = {
                value: parseFloat(event.data[1].data?.value),
                override: false,
              };
              this.setState({ formFields: formFields });
              // this.total();
            }
            if (event.data[0] === "FO-Sale-Price") {
              const formFields = this.state.formFields;
              formFields["sale_price"] = {
                value: parseFloat(event.data[1].data?.value),
                override: false,
              };
              this.setState({ formFields: formFields });
              this.total();
            }
            if (
              event.data[0] !== "FO-Total-PPP" &&
              this.state.formFields["overhead_cost_per_part"] &&
              this.state.formFields["material_cost_per_part"] &&
              this.state.formFields["powder_removal_cost_per_part"] &&
              this.state.formFields["laboratory_cost_per_part"] &&
              this.state.formFields["post_processing_cost_per_part"]
            ) {

              this.total();
            }

            var d2 = document.getElementById("d2");
            if (window.reportDognutChart) {
              window.reportDognutChart.destroy();
            }
            window.reportDognutChart = new Chart(d2, {
              plugins: [ChartDataLabels],
              type: "doughnut",

              data: {
                datasets: [
                  {
                    data: [
                      (this.state.formFields &&
                        this.state.formFields["post_processing_cost_per_part"] !==
                        undefined &&
                        this.state.formFields["total_cost_per_part"] !== undefined
                        ? parseFloat(
                          this.state.formFields[
                            "post_processing_cost_per_part"
                          ].value
                        ) /
                        parseFloat(
                          this.state.formFields["total_cost_per_part"].value
                        )
                        : 0) * 100,
                      (this.state.formFields &&
                        this.state.formFields["system_cost_per_part"] !==
                        undefined &&
                        this.state.formFields["total_cost_per_part"] !== undefined
                        ? parseFloat(
                          this.state.formFields["system_cost_per_part"].value
                        ) /
                        parseFloat(
                          this.state.formFields["total_cost_per_part"].value
                        )
                        : 0) * 100,
                      (this.state.formFields &&
                        this.state.formFields["material_cost_per_part"] !==
                        undefined &&
                        this.state.formFields["total_cost_per_part"] !== undefined
                        ? parseFloat(
                          this.state.formFields["material_cost_per_part"]
                            .value
                        ) /
                        parseFloat(
                          this.state.formFields["total_cost_per_part"].value
                        )
                        : 0) * 100,
                    ],
                    backgroundColor: ["#7761cf", "#fcc342", "#00bff3"],
                    borderWidth: 0,
                  },
                ],
                borderWidth: 0,
                // These labels appear in the legend and in the tooltips when hovering different arcs

                labels: [
                  "Post Processing",
                  "System Cost Per Part",
                  "Material Cost Per Part",
                ],
              },
              options: {
                responsive: true,
                maintainAspectRatio: false,
                cutoutPercentage: 50,
                rotation: 1.5 * Math.PI,
                circumference: 2 * Math.PI,
                legend: {
                  display: false,
                  borderWidth: 0,
                  position: "bottom",
                  labels: {
                    fontColor: "#c9cbcd",
                    fontFamily: "'Montserrat', sans-serif",
                    fontWeight: 600,
                    fontSize: 10,
                    padding: 30,
                  },
                },
                tooltips: {
                  enabled: false,
                },
                plugins: {
                  datalabels: {
                    formatter: (value, ctx) => {
                      let sum = 0;
                      let dataArr = ctx.chart.data.datasets[0].data;
                      // eslint-disable-next-line
                      dataArr.map((data) => {
                        sum += data;
                      });
                      let percentage = ((value * 100) / sum).toFixed(0) + "%";
                      return percentage;
                    },
                    color: "#ffffff",
                    font: {
                      family: "Open Sans",
                      size: 15,
                      weight: 400,
                    },
                  },
                },
              },
            });

            setTimeout(() => {
              var url_base64jp = document
                .getElementById("d2")
                .toDataURL("image/jpg");
              console.log("URL", url_base64jp);
              this.setState({
                donutImage: url_base64jp,
              });
            }, 2000);
          });
        }
      );
    }

  }

  total() {
    console.log({
      data_preparation_cost_per_part: this.state.formFields[
        "data_preparation_cost_per_part"
      ]
        ? this.state.formFields["data_preparation_cost_per_part"]?.value
        : 0,
      am_system_cost_per_part:
        this.state.formFields &&
          this.state.formFields["am_system_cost_per_part"]
          ? this.state.formFields["am_system_cost_per_part"]?.value
          : 0,
      manual_labor_cost_per_part:
        this.state.formFields["manual_labor_cost_per_part"] &&
        this.state.formFields["manual_labor_cost_per_part"]?.value,
      operating_expenses_cost_per_part: this.state.formFields[
        "operating_expenses_cost_per_part"
      ]
        ? this.state.formFields["operating_expenses_cost_per_part"]?.value
        : 0,
      material_cost_per_part:
        this.state.formFields["material_cost_per_part"]?.value,
      powder_removal_cost_per_part: this.state.formFields[
        "powder_removal_cost_per_part"
      ]
        ? this.state.formFields["powder_removal_cost_per_part"]?.value
        : 0,
      // post_processing_cost_per_part: //TODO this.state.formFields['post_processing_cost_per_part'].value,
      laboratory_cost_per_part: this.state.formFields[
        "laboratory_cost_per_part"
      ]
        ? this.state.formFields["laboratory_cost_per_part"]?.value
        : 0,
      overhead_cost_per_part:
        this.state.formFields && this.state.formFields["overhead_cost_per_part"]
          ? this.state.formFields["overhead_cost_per_part"]?.value
          : 1,
    });
    this.state.socketObj.emit("calculator", {
      key: "FO-Total-PPP",
      input: {
        data_preparation_cost_per_part: this.state.formFields[
          "data_preparation_cost_per_part"
        ]
          ? this.state.formFields["data_preparation_cost_per_part"]?.value
          : 0,
        am_system_cost_per_part:
          this.state.formFields &&
            this.state.formFields["am_system_cost_per_part"]
            ? this.state.formFields["am_system_cost_per_part"]?.value
            : 0,
        manual_labor_cost_per_part: this.state.formFields[
          "manual_labor_cost_per_part"
        ]
          ? this.state.formFields["manual_labor_cost_per_part"]?.value
          : 0,
        operating_expenses_cost_per_part: this.state.formFields[
          "operating_expenses_cost_per_part"
        ]
          ? this.state.formFields["operating_expenses_cost_per_part"]?.value
          : 0,
        material_cost_per_part: this.state.formFields["material_cost_per_part"]
          ? this.state.formFields["material_cost_per_part"]?.value
          : 0,
        powder_removal_cost_per_part: this.state.formFields[
          "powder_removal_cost_per_part"
        ]
          ? this.state.formFields["powder_removal_cost_per_part"]?.value
          : 0,
        post_processing_cost_per_part:
          sessionStorage.getItem("technologyName") &&
            sessionStorage.getItem("technologyName").split(" ")[0] === "Polymer"
            ? 0
            : this.state.formFields["post_processing_cost_per_part"]?.value,
        laboratory_cost_per_part: this.state.formFields[
          "laboratory_cost_per_part"
        ]
          ? this.state.formFields["laboratory_cost_per_part"]?.value
          : 0,
        overhead_cost_per_part:
          this.state.formFields &&
            this.state.formFields["overhead_cost_per_part"]
            ? this.state.formFields["overhead_cost_per_part"]?.value
            : 1,
      },
    });
  }

  getReportData(report_id) {
    return new Promise((resolve, reject) => {
      const access_token = sessionStorage.getItem("access_token");
      axios
        .get(`${config.serviceUrl}report/${report_id}`, {
          headers: {
            Authorization: access_token,
          },
        })
        .then(function (response) {

          resolve(response);
        })
        .catch(function (error) {
          console.log(error);
          reject(error);
        });
    });
  }

  isNumber(n) {
    return /^-?[\d.]+(?:e-?\d+)?$/.test(n);
  }

  renderPartSupportVolume() {
    if (
      sessionStorage.getItem("technologyName") === "Metal (Simulation)" ||
      sessionStorage.getItem("technologyName") === "Metal (Estimation)"
    ) {
      return (
        <tr>
          <td>Part Support Volume</td>
          <td>
            {this.state.formFields && this.state.formFields !== undefined &&
              this.state.formFields["part_support_volume"] !== undefined
              ? this.state.formFields["part_support_volume"].value
              : 0}{" "}
            cm<sup>3</sup>
          </td>
        </tr>
      );
    }
  }

  renderPartsPerJob() {
    if (
      this.state.formFields !== null &&
      this.state.formFields["total_no_of_parts"] !== undefined
    ) {
      return (
        <tr>
          <td>Parts per Job</td>
          <td>
            {this.state.formFields !== undefined &&
              this.state.formFields["total_no_of_parts"] !== undefined
              ? this.state.formFields["total_no_of_parts"].value
              : 0}
          </td>
        </tr>
      );
    } else {
      return (
        <tr>
          <td>Parts per Job</td>
          <td>
            {this.state.formFields !== null &&
              this.state.formFields["part_per_job"] !== undefined
              ? this.state.formFields["part_per_job"].value
              : 0}
          </td>
        </tr>
      );
    }
  }
  setImageHere = (obj) => {
    if (obj && obj.length === 0) {
      return;
    }
    console.log("in setImageHere", obj);

    var reader = new FileReader();
    var url = reader.readAsDataURL(obj[0]);
    this.setState({ serverImageObj: obj[0] });
    reader.onloadend = function (e) {
      console.log("reader.result", reader.result);
      this.setState({
        image: reader.result,
      });
    }.bind(this);
    console.log(url);
    // this.setState({ image: obj[0] });
  };

  renderCoolingTimePerJob() {
    if (
      sessionStorage.getItem("technologyName") &&
      sessionStorage.getItem("technologyName").split(" ")[0] === "Polymer"
    ) {
      return (
        <tr>
          <td>Cooling time per Job</td>
          <td>
            {this.state.formFields && this.state.formFields !== undefined &&
              this.state.formFields["cool_down_time_in_system"] !== undefined
              ? this.state.formFields["cool_down_time_in_system"].value
              : 0}
          </td>
        </tr>
      );
    } else {
    }
  }

  renderPostProcessing() {
    if (
      sessionStorage.getItem("technologyName") &&
      sessionStorage.getItem("technologyName").split(" ")[0] === "Metal"
    ) {
      return (
        <tr>
          <td>Post Processing</td>
          <td>Labor and other equipment</td>
          <td>
            <div className="vcenter2">
              <div className="table-inputbox3">
                <input
                  type="number"
                  name="post_processing_currency_per_job"
                  className="login-input"
                  onChange={(e) => {
                    this.getFieldValues(e);
                  }}
                />
              </div>
            </div>
          </td>
          <td>
            {this.state.formFields !== null &&
              this.state.formFields["post_processing_cost_per_part"]
              ? this.state.formFields["post_processing_cost_per_part"].value
              : 0}{" "}
            {this.state.currencySymbol}
          </td>
        </tr>
      );
    }
  }

  getFieldValues(e) {

    const { name, value } = e.target;
    const formFields = this.state.formFields;
    formFields[name] = { value, override: false };
    this.setState({ formFields: formFields });
    if (
      formFields["data_preparation_currency_per_hour"] &&
      formFields["data_preparation_hour_per_job"]
    ) {

      this.state.socketObj.emit("calculator", {
        key: "FO-Data-Prep",
        input: {
          data_preparation_currency_per_hour:
            formFields["data_preparation_currency_per_hour"].value,
          data_preparation_hour_per_job:
            formFields["data_preparation_hour_per_job"].value,
          parts_per_job:
            this.state.formFields && this.state.formFields["parts_per_job"]
              ? this.state.formFields["parts_per_job"].value
              : this.state.formFields["total_no_of_parts"].value,
        },
      });
    }
    if (
      formFields["laboratory_currency_per_job"] &&
      formFields["laboratory_currency_per_kg"]
    ) {
      this.state.socketObj.emit("calculator", {
        key: "FO-Laboratory-Cost-Part",
        input: {
          laboratory_currency_per_job:
            this.state.formFields["laboratory_currency_per_job"].value,
          laboratory_currency_per_kg:
            this.state.formFields["laboratory_currency_per_kg"].value,
          parts_per_job:
            this.state.formFields && this.state.formFields["parts_per_job"]
              ? this.state.formFields["parts_per_job"].value
              : this.state.formFields["total_no_of_parts"].value,
        },
      });
    }
    if (
      formFields["overhead_currency_per_job"] &&
      formFields["overhead_h_per_part"]
    ) {
      this.state.socketObj.emit("calculator", {
        key: "FO-Overhead-Cost-Part",
        input: {
          overhead_currency_per_job:
            this.state.formFields["overhead_currency_per_job"].value,
          overhead_h_per_part:
            this.state.formFields["overhead_h_per_part"].value,
          parts_per_job:
            this.state.formFields && this.state.formFields["parts_per_job"]
              ? this.state.formFields["parts_per_job"].value
              : this.state.formFields["total_no_of_parts"].value,
        },
      });
    }
    if (
      formFields["manual_labor_currency_per_hour"] &&
      formFields["manual_labor_currency_per_hour"]
    ) {
      this.state.socketObj.emit("calculator", {
        key: "FO-Manual-Labor-Cost-Part",
        input: {
          manual_labor_currency_per_hour:
            this.state.formFields["manual_labor_currency_per_hour"].value,
          manual_labor_h_per_job:
            this.state.formFields["manual_labor_h_per_job"].value,
          parts_per_job:
            this.state.formFields && this.state.formFields["parts_per_job"]
              ? this.state.formFields["parts_per_job"].value
              : this.state.formFields["total_no_of_parts"].value,
        },
      });
    }
    if (
      formFields["operating_expenses_currency_per_hour"] &&
      formFields["operating_expenses_currency_per_hour"]
    ) {

      this.state.socketObj.emit("calculator", {
        key: "FO-Oper-Expense-Cost-Part",
        input: {
          build_time: this.state.formFields["build_time"].value,
          operating_expenses_currency_per_hour:
            this.state.formFields["operating_expenses_currency_per_hour"].value,
          parts_per_job:
            this.state.formFields && this.state.formFields["parts_per_job"]
              ? this.state.formFields["parts_per_job"].value
              : this.state.formFields["total_no_of_parts"].value,
        },
      });
    }
    if (formFields["powder_removal_currency_per_job"]) {
      this.state.socketObj.emit("calculator", {
        key: "FO-Powd-Cost-Part",
        input: {
          powder_removal_currency_per_job:
            formFields["powder_removal_currency_per_job"].value,
          parts_per_job:
            this.state.formFields && this.state.formFields["parts_per_job"]
              ? this.state.formFields["parts_per_job"].value
              : this.state.formFields["total_no_of_parts"].value,
        },
      });
    }
    if (formFields["post_processing_currency_per_job"]) {
      this.state.socketObj.emit("calculator", {
        key: "FO-Post-Process-Cost-Part",
        input: {
          post_processing_currency_per_job:
            formFields["post_processing_currency_per_job"].value,
          parts_per_job:
            this.state.formFields && this.state.formFields["parts_per_job"]
              ? this.state.formFields["parts_per_job"].value
              : this.state.formFields["total_no_of_parts"].value,
        },
      });
    }

    if (formFields["margin"]) {
      this.state.socketObj.emit("calculator", {
        key: "FO-Sale-Price",
        input: {
          total_cost_per_part: formFields["total_cost_per_part"].value,
          margin: formFields["margin"].value,
        },
      });
    }
  }

  refetchFormFieldsFunc() {
    console.log("in refetchFormFieldsFunc formFields", this.state.formFields);
    return this.state.formFields;
  }


  render() {
    console.log("reportfields", this.state.formFields);

    return (
      <React.Fragment>
        <ToastContainer />
        <GetNames formFields={this.state.formFields} />

        <div id="popup-container">
          <div id="popup-box">
            <div id="popup-details">
              <p>Select File Type to Download</p>
              <div className="inputbox">
                <select
                  className="myselect2"
                  value={this.state.downloadTypeValue}
                  onChange={(e) => {

                    if (e.target.value === "0" || e.target.value === "1") {
                      this.setState({
                        downloadTypeValue: parseInt(e.target.value),
                      });
                    } else {
                      this.setState({ downloadTypeValue: -1 });
                    }
                  }}
                >
                  <option value="-1">Select Type</option>
                  <option value="0">PDF</option>
                  <option value="1">Word</option>
                  {/* <option value="2">Excel</option> */}
                </select>
              </div>
              <div id="btn-row2">
                <div
                  className="button3 gray"
                  id="cancel-btn"
                  onClick={() => {
                    this.setState({ downloadTypeValue: -1 });
                  }}
                >
                  <p>Cancel</p>
                </div>
                <div
                  id="pop-up-confirm-btn"
                  className="button3"
                  onClick={() => {
                    if (
                      this.state.downloadTypeValue === 0 ||
                      this.state.downloadTypeValue === 1
                    ) {
                      this.setState({ downloadPdf: true });
                      setTimeout(() => {
                        this.setState({ downloadPdf: false });
                      }, 5000);

                    } else {
                      this.setState({ downloadPdf: false });
                    }
                    // State of Download Type and Value...
                    // console.log(
                    //   "this.state.downloadTypeValue",
                    //   this.state.downloadTypeValue,
                    //   "this.state.downloadPdf",
                    //   this.state.downloadPdf
                    // );
                  }}
                >
                  <p>Confirm</p>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* modal for report save  */}
        <div id="report-popup-container">
          <div id="popup-box">
            <div id="popup-details">
              <p>Report Name</p>
              <div className="inputbox">
                <input className="reportInput" placeholder="Enter Name" value={this.setState.report_name} onChange={(e) => {
                  this.setState({ report_name: e.target.value });
                }} type="text" />
              </div>
              <div id="btn-row2">
                <div
                  className="button3 gray"
                  id="cancel-report-btn"
                  onClick={() => {
                    this.setState({ report_name: '' });
                    this.setState({ downloadTypeValue: -1 });
                  }}
                >
                  <p>Cancel</p>
                </div>
                <div
                  id="pop-up-confirm-btn"
                  className="button3"
                  onClick={async () => {
                    if (this.state.isLoading) {
                      return
                    }
                    this.setState({ isLoading: true });
                    toast.info("Please Wait...");

                    const access_token = sessionStorage.getItem("access_token");
                    let data = new FormData();
                    if (this.state.serverImageObj) {
                      data.append("image", this.state.serverImageObj);
                    }
                    data.append(
                      "report_data",
                      JSON.stringify(this.state.formFields)
                    );
                    data.append(
                      "report_name",
                      this.state.report_name
                    );

                    await axios
                      .post(`${config.serviceUrl}report`, data, {
                        headers: {
                          accept: "application/json",
                          "Accept-Language": "en-US,en;q=0.8",
                          "Content-Type": `multipart/form-data; boundary=${data._boundary}`,
                          Authorization: access_token,
                        },
                      })
                      .then(function (response) {

                        if (response.data) {
                          toast.success("Report saved Successfully");
                        }
                        document.getElementById('cancel-report-btn').click();
                      })
                      .catch(function (error) {
                        console.log(error);

                      });
                    this.setState({ isLoading: false });

                  }}
                >
                  <p>{this.state.isLoading ? "Loading.." : "Confirm"}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Navbar />

        <div className="row pad-50">
          <div className="col p-0 ">
            <div id="title-row2">
              {/* {this.state.disableInputs ? (
                                ''                    

                            ) : ( */}
              {/* <div
                style={{ color: "red" }}
                onClick={async()=>{
                    await getDownloadUrl();
                }}
                download={"abc.docx"}
              >
                Download
              </div> */}
              <div
                className="button3 early-break myorder-1"
                id="save-report-btn"
              >
                <img alt="" src="../assets/print.png" width="23" height="20" />
                <p>Save </p>
              </div>
              {/* )} */}
              {
                this.state.formFields && this.state.downloadPdf && (
                  <PdfGenerator
                    formFields={this.state.formFields}
                    image={this.state.image}
                    symbol={this.state.currencySymbol}
                    donutImage={document
                      .getElementById("d2")
                      .toDataURL("image/png")}
                    typeDocument={this.state.downloadTypeValue}
                  />
                )}

              <div id="center-title">
                <p className="t-2">
                  Cost Report for{" "}
                  {this.state.formFields &&
                    this.state.formFields["machine_name"]
                    ? this.state.formFields["machine_name"]
                    : ""}
                </p>
                <p className="s-1">{sessionStorage.getItem("technologyName")}</p>
              </div>

              <div className="button2 early-break myorder-1 " id="print-btn">
                <img
                  alt=""
                  src="../assets/download.png"
                  width="22"
                  height="22"
                />
                <p>Download</p>
              </div>
              {/* <a href="./overheads-consumables" className="button2 early-break myorder-2">
                                <img alt=""  src="../assets/download.png" width="22" height="22" /> */}

              {/* onClicks={() => {

                                let input = window.document.getElementsByClassName("donutbox")[0];
                                console.log('input', input)
                                html2canvas(input).then(canvas => {

                                    const img = canvas.toDataURL("image/png");
                                    console.log('img', img)
                                    return img;
                                })
                            }}  */}
              {/* </a> */}
            </div>
            <div id="report-head" className="container-fluid">
              <div className="row">
                <div className="col-lg-4 col-md-6 col-sm-12 p-0">
                  <p className="t-3">Part Info</p>
                  <table className="table table-responsive-md">
                    <tbody>
                      <tr>
                        <td>Part Volume</td>
                        <td>
                          {this.state.formFields !== null
                            ? this.state.formFields["part_volume"].value
                            : 0}{" "}
                          cm<sup>3</sup>
                        </td>
                      </tr>
                      <tr>
                        <td>Part Weight</td>
                        <td>
                          {this.state.formFields !== null &&
                            this.state.formFields["part_weight"]
                            ? parseFloat(this.state.formFields["part_weight"].value).toFixed(4)
                            : 0}{" "}
                          g
                        </td>
                      </tr>
                      {this.renderPartSupportVolume()}
                    </tbody>
                  </table>
                  <p className="t-3">Cost Distribution</p>
                  <div>
                    <div className="donutbox">
                      <canvas id="d2"></canvas>
                    </div>
                  </div>
                  <div className="center legendv">
                    <div>
                      <div className="custom-legend-item">
                        <div className="custom-legend-icon blue"></div>
                        <p>Post Processing</p>
                      </div>
                      <div className="custom-legend-item">
                        <div className="custom-legend-icon green"></div>
                        <p>System Cost Per Part</p>
                      </div>
                      <div className="custom-legend-item">
                        <div className="custom-legend-icon lightblue"></div>
                        <p>Material Cost Per Part</p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-4 col-md-6 col-sm-12 p-0">
                  <p className="t-3">Job Details</p>
                  <table className="table table-responsive-md">
                    <tbody>
                      {this.renderPartsPerJob()}
                      <tr>
                        <td>Build time per Job</td>
                        <td>
                          {this.state.formFields !== null
                            ? this.state.formFields["build_time_per_job"]
                              ? this.state.formFields["build_time_per_job"]
                                .value
                              : this.state.formFields["build_time"].value
                            : 0}
                        </td>
                      </tr>
                      <tr>
                        <td>Set up time per Job</td>
                        <td>
                          {this.state.formFields !== null
                            ? this.state.formFields["setup_time"].value
                            : 0}
                        </td>
                      </tr>
                      {this.renderCoolingTimePerJob()}
                      <tr>
                        <td>Unpacking time per Job</td>
                        <td>
                          {this.state.formFields !== null
                            ? this.state.formFields["unpacking_time_per_job"]
                              .value
                            : 0}
                        </td>
                      </tr>
                      <tr>
                        <td>Material</td>
                        <td>
                          {this.state.formFields &&
                            this.state.formFields["material_name"]
                            ? this.state.formFields["material_name"]
                            : ""}
                        </td>
                      </tr>
                      <tr>
                        <td>Parameter</td>
                        <td>
                          {this.state.formFields &&
                            this.state.formFields["parameter_name_value"]
                            ? this.state.formFields["parameter_name_value"]
                            : ""}
                        </td>
                      </tr>
                      <tr>
                        <td>Scrap Rate %</td>
                        <td>
                          {/* {this.state.disableInputs ? (
                                                        this.state.formFields && this.state.formFields['scrape_rate'] ? this.state.formFields['scrape_rate'].value : ''
                                                    ) : ( */}
                          <div className="vcenter2">
                            <div className="table-inputbox2">
                              <input
                                type="number"
                                name="scrape_rate"
                                className="login-input "
                                value={
                                  this.state.formFields &&
                                    this.state.formFields["scrape_rate"]
                                    ? this.state.formFields["scrape_rate"].value
                                    : 0
                                }
                                onChange={(e) => {
                                  this.getFieldValues(e);
                                }}
                              />
                            </div>
                          </div>
                          {/* )} */}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <div className="col-lg-4 col-md-12 col-sm-12 ">
                  <div id="upload-box">
                    <ImageUpload
                      setImage={this.setImageHere}
                      defaultImageProp={this.state.defaultCostImageUrl}
                    />
                  </div>
                </div>
              </div>
            </div>

            <div id="report-body">
              <table className="table top-border table-responsive-md">
                <thead>
                  <tr>
                    <th>Preprocessing</th>
                    <th>Influencing Factor</th>
                    <th>{this.state.currencySymbol} / hour</th>
                    <th>h / Job</th>
                    <th>Cost / Part</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>Data Preperation</td>
                    <td>Labor</td>
                    <td>
                      {/* {this.state.disableInputs ? (
                                                this.state.formFields && this.state.formFields['data_preparation_currency_per_hour'] ? this.state.formFields['data_preparation_currency_per_hour'].value : ''
                                            ) : ( */}
                      <div className="vcenter2">
                        <div className="table-inputbox3">
                          <input
                            type="number"
                            name="data_preparation_currency_per_hour"
                            className="login-input"
                            onChange={(e) => {
                              this.getFieldValues(e);
                            }}
                          />
                        </div>
                      </div>
                      {/* )} */}
                    </td>
                    <td>
                      {/* {this.state.disableInputs ? (
                                                this.state.formFields && this.state.formFields['data_preparation_hour_per_job'] ? this.state.formFields['data_preparation_hour_per_job'].value : ''
                                            ) : ( */}
                      <div className="vcenter2">
                        <div className="table-inputbox3">
                          <input
                            type="number"
                            name="data_preparation_hour_per_job"
                            className="login-input"
                            onChange={(e) => {
                              this.getFieldValues(e);
                            }}
                          />
                        </div>
                      </div>
                      {/* )} */}
                    </td>
                    <td>
                      {this.state.formFields !== null &&
                        this.state.formFields["data_preparation_cost_per_part"]
                        ? this.state.formFields[
                          "data_preparation_cost_per_part"
                        ].value
                        : 0}{" "}
                      {this.state.currencySymbol}
                    </td>
                  </tr>
                </tbody>
              </table>

              <table className="table top-border table-responsive-md">
                <thead>
                  <tr>
                    <th>Build Process</th>
                    <th>Influencing Factor</th>
                    <th> {this.state.currencySymbol} / hour</th>
                    <th>h / Job</th>
                    <th>Cost / Part</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>AM System</td>
                    <td>Build, set-up, cooling, unpacking time</td>
                    <td>
                      {this.state.formFields !== null &&
                        this.state.formFields["system_cost_per_hour"]
                        ? this.state.formFields["system_cost_per_hour"].value
                        : 0}
                    </td>
                    <td>
                      {this.state.formFields !== null &&
                        this.state.formFields["am_system_h_job"]
                        ? this.state.formFields["am_system_h_job"].value
                        : 0}
                    </td>
                    <td>
                      {this.state.formFields !== null &&
                        this.state.formFields["am_system_cost_per_part"]
                        ? this.state.formFields["am_system_cost_per_part"].value
                        : 0}{" "}
                      {this.state.currencySymbol}
                    </td>
                  </tr>
                  <tr>
                    <td>Manual Labor</td>
                    <td>Manual, set-up and unpacking</td>
                    <td>
                      {/* {this.state.disableInputs ? (
                                                this.state.formFields && this.state.formFields['manual_labor_currency_per_hour'] ? this.state.formFields['manual_labor_currency_per_hour'].value : ''
                                            ) : ( */}
                      <div className="vcenter2">
                        <div className="table-inputbox3">
                          <input
                            type="number"
                            name="manual_labor_currency_per_hour"
                            className="login-input "
                            onChange={(e) => {
                              this.getFieldValues(e);
                            }}
                          />
                        </div>
                      </div>
                      {/* )} */}
                    </td>
                    <td>
                      {this.state.formFields !== null &&
                        this.state.formFields["manual_labor_h_per_job"]
                        ? this.state.formFields["manual_labor_h_per_job"].value
                        : 0}
                    </td>
                    <td>
                      {this.state.formFields !== null &&
                        this.state.formFields["manual_labor_cost_per_part"]
                        ? this.state.formFields["manual_labor_cost_per_part"]
                          .value
                        : 0}{" "}
                      {this.state.currencySymbol}
                    </td>
                  </tr>
                  <tr>
                    <td>Operating Expenses</td>
                    <td>Inert gas, power consumption</td>
                    <td>
                      {/* {this.state.disableInputs ? (
                                                this.state.formFields && this.state.formFields['operating_expenses_currency_per_hour'] ? this.state.formFields['operating_expenses_currency_per_hour'].value : ''
                                            ) : ( */}
                      <div className="vcenter2">
                        <div className="table-inputbox3">
                          <input
                            type="number"
                            name="operating_expenses_currency_per_hour"
                            className="login-input "
                            onChange={(e) => {
                              this.getFieldValues(e);
                            }}
                          />
                        </div>
                      </div>
                      {/* )} */}
                    </td>
                    <td>
                      {this.state.formFields !== null &&
                        this.state.formFields["build_time"]
                        ? this.state.formFields["build_time"].value
                        : 0}
                    </td>
                    <td>
                      {this.state.formFields !== null &&
                        this.state.formFields["operating_expenses_cost_per_part"]
                        ? this.state.formFields[
                          "operating_expenses_cost_per_part"
                        ].value
                        : 0}{" "}
                      {this.state.currencySymbol}
                    </td>
                  </tr>
                </tbody>
              </table>

              <table className="table top-border table-responsive-md">
                <thead>
                  <tr>
                    <th>Material</th>
                    <th>Influencing Factor</th>
                    <th> {this.state.currencySymbol} / Kg</th>
                    <th>Kg / Job</th>
                    <th>Cost / Part</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>Material Cost</td>
                    <td>Refreshment rates, batch</td>
                    <td>
                      {this.state.formFields !== null &&
                        this.state.formFields["material_price"]
                        ? this.state.formFields["material_price"].value
                        : 0}
                    </td>
                    <td>
                      {this.state.formFields !== null &&
                        this.state.formFields["material_cost_kg_per_job"]
                        ? this.state.formFields["material_cost_kg_per_job"]
                          .value
                        : 0}
                    </td>
                    <td>
                      {this.state.formFields !== null &&
                        this.state.formFields["material_cost_per_part"]
                        ? this.state.formFields["material_cost_per_part"].value
                        : 0}{" "}
                      {this.state.currencySymbol}
                    </td>
                  </tr>
                </tbody>
              </table>
              <table className="table top-border table-responsive-md">
                <thead>
                  <tr>
                    <th>Post Processing</th>
                    <th>Influencing Factor</th>
                    <th> {this.state.currencySymbol} / Job</th>
                    <th>Cost / Part</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>Powder removal & handling</td>
                    <td>Powder handling including labor</td>
                    <td>
                      {/* {this.state.disableInputs ? (
                                                this.state.formFields && this.state.formFields['powder_removal_currency_per_job'] ? this.state.formFields['powder_removal_currency_per_job'].value : ''
                                            ) : ( */}
                      <div className="vcenter2">
                        <div className="table-inputbox3">
                          <input
                            type="number"
                            name="powder_removal_currency_per_job"
                            className="login-input"
                            onChange={(e) => {
                              this.getFieldValues(e);
                            }}
                          />
                        </div>
                      </div>
                      {/* )} */}
                    </td>
                    <td>
                      {this.state.formFields !== null &&
                        this.state.formFields["powder_removal_cost_per_part"]
                        ? this.state.formFields["powder_removal_cost_per_part"]
                          .value
                        : 0}{" "}
                      {this.state.currencySymbol}
                    </td>
                  </tr>

                  {this.renderPostProcessing()}
                </tbody>
              </table>

              <table className="table top-border table-responsive-md">
                <thead>
                  <tr>
                    <th>Quality Assurance</th>
                    <th>Influencing Factor</th>
                    <th> {this.state.currencySymbol} / hour</th>
                    <th>h / Job</th>
                    <th>Cost / Part</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>Laboratory</td>
                    <td>Equipment, labor, consumption materials</td>
                    <td>
                      {/* {this.state.disableInputs ? (
                                                this.state.formFields && this.state.formFields['laboratory_currency_per_job'] ? this.state.formFields['laboratory_currency_per_job'].value : ''
                                            ) : ( */}
                      <div className="vcenter2">
                        <div className="table-inputbox3">
                          <input
                            type="number"
                            name="laboratory_currency_per_job"
                            className="login-input"
                            onChange={(e) => {
                              this.getFieldValues(e);
                            }}
                          />
                        </div>
                      </div>
                      {/* )} */}
                    </td>
                    <td>
                      {/* {this.state.disableInputs ? (
                                                this.state.formFields && this.state.formFields['laboratory_currency_per_kg'] ? this.state.formFields['laboratory_currency_per_kg'].value : ''
                                            ) : ( */}
                      <div className="vcenter2">
                        <div className="table-inputbox3">
                          <input
                            type="number"
                            name="laboratory_currency_per_kg"
                            className="login-input"
                            onChange={(e) => {
                              this.getFieldValues(e);
                            }}
                          />
                        </div>
                      </div>
                      {/* )} */}
                    </td>
                    <td>
                      {this.state.formFields !== null &&
                        this.state.formFields["laboratory_cost_per_part"]
                        ? this.state.formFields["laboratory_cost_per_part"]
                          .value
                        : 0}{" "}
                      {this.state.currencySymbol}
                    </td>
                  </tr>
                </tbody>
              </table>

              <table className="table top-border table-responsive-md">
                <thead>
                  <tr>
                    <th>Overhead</th>
                    <th>Influencing Factor</th>
                    <th> {this.state.currencySymbol} / hour</th>
                    <th>h / Job</th>
                    <th>Cost / Part</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>Overhead share</td>
                    <td>Plant management, facility costs</td>
                    <td>
                      {/* {this.state.disableInputs ? (
                                                this.state.formFields && this.state.formFields['overhead_currency_per_job'] ? this.state.formFields['overhead_currency_per_job'].value : ''
                                            ) : ( */}
                      <div className="vcenter2">
                        <div className="table-inputbox3">
                          <input
                            type="number"
                            name="overhead_currency_per_job"
                            className="login-input"
                            onChange={(e) => {
                              this.getFieldValues(e);
                            }}
                          />
                        </div>
                      </div>
                      {/* )} */}
                    </td>
                    <td>
                      {/* {this.state.disableInputs ? (
                                                this.state.formFields && this.state.formFields['overhead_h_per_part'] ? this.state.formFields['overhead_h_per_part'].value : ''
                                            ) : ( */}
                      <div className="vcenter2">
                        <div className="table-inputbox3">
                          <input
                            type="number"
                            name="overhead_h_per_part"
                            className="login-input"
                            onChange={(e) => {
                              this.getFieldValues(e);
                            }}
                          />
                        </div>
                      </div>
                      {/* )} */}
                    </td>
                    <td>
                      {this.state.formFields !== null &&
                        this.state.formFields["overhead_cost_per_part"]
                        ? this.state.formFields["overhead_cost_per_part"].value
                        : 0}{" "}
                      {this.state.currencySymbol}
                    </td>
                  </tr>
                </tbody>
              </table>

              <table className="table table-responsive-md">
                <thead>
                  <th></th>
                  <th></th>
                  <th></th>
                </thead>
                <tbody>
                  <tr className="bold">
                    <td>Total Cost Per Part</td>
                    <td></td>

                    <td>
                      {this.state.formFields &&
                        this.state.formFields["total_cost_per_part"]
                        ? this.state.formFields["total_cost_per_part"].value
                        : 0}{" "}
                      {this.state.currencySymbol}
                    </td>
                  </tr>
                  <tr>
                    <td></td>
                    <td>Margin</td>

                    <td>
                      {/* {this.state.disableInputs ? (
                                                this.state.formFields && this.state.formFields['margin'] ? this.state.formFields['margin'].value : ''
                                            ) : ( */}
                      <div className="vcenter2">
                        <div className="table-inputbox3">
                          <input
                            type="number"
                            name="margin"
                            className="login-input"
                            onChange={(e) => {
                              this.getFieldValues(e);
                            }}
                          />
                        </div>
                        <p className="bold"> %</p>
                      </div>
                      {/* )} */}
                    </td>
                  </tr>
                  <tr className="bold">
                    <td>Sales Price</td>
                    <td></td>

                    <td>
                      {this.state.formFields &&
                        this.state.formFields["sale_price"]
                        ? this.state.formFields["sale_price"].value
                        : 0}{" "}
                      {this.state.currencySymbol}
                    </td>
                  </tr>
                </tbody>
              </table>
              {/* <p className="disclaimer">* Assumptions: &lt;metal name&gt;, list price, depreciation period &lt;years&gt; years, interest rate &lt;rate&gt;, utilization &lt;utilization&lt; h / year *</p> */}
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default CostReport;
