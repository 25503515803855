import React, { useState } from "react";
import ReactTooltip from "react-tooltip";
import { Link } from "react-router-dom";
import Navbar from "./Nav.jsx";
import Banner from "./Banner";
import { overHeadConsumables } from "atoms/General/index.js";
import { useRecoilState } from "recoil";
import { useApolloClient } from "@apollo/react-hooks";
import { ToastContainer, toast } from "react-toastify";
import Spinner from "../../Spinner.svg";
import socketIOClient from "socket.io-client";
import constant from "../../utils/constants";
import { RESPONSE_MESSAGES, FIELD_NAMES } from "../../utils/constantenums.js";
import gql from "graphql-tag";
import { useMutation } from "@apollo/react-hooks";
import BatchFillingPop from "./BatchFillingPopUp";
import { useAccess } from "state/hooks.js";
import { CONDITIONALROUTES } from "./ConditionalEnums/index.js";

const SAVE_CLIENT_CALCULATIONS = gql`
  mutation saveClientCalculations($calculations: Object) {
    saveClientCalculations(calculations: $calculations)
  }
`;

const RESET_CUSTOMIZED_FIELD = gql`
  mutation resetCustomizedValue($field_id: Int!) {
    resetCustomizedValue(field_id: $field_id)
  }
`;

const SAVE_CUSTOMIZED_FIELD = gql`
  mutation createClientCustomizedField($machine_id:Int!,$region_id: Int!,$technology_id: Int!,$field_id: Int!,$calculator_type: String!,$value: Float!,$param_id: Int,$material_id: Int) {
    createClientCustomizedField(machine_id: $machine_id,region_id: $region_id,technology_id: $technology_id,field_id: $field_id,calculator_type: $calculator_type,value: $value,param_id: $param_id,material_id: $material_id,){
      machine_id
      region_id
      technology_id
      field_id
      calculator_type
      value
      material_id
      param_id
      
    }
  }
`;

const CalculatorHeader = () => {
  let technology_name = sessionStorage.getItem("technology");
  let step_type_id = +sessionStorage.getItem("stepTypeId");
  let Access = useAccess();
  const [fieldDataAvailability, setFieldDataAvailability] = useState(false);
  // const calculatorType = useRecoilValue(CalculatorType)
  // console.log("calculatorType: ", calculatorType)

  const calculatorType = sessionStorage.getItem("calculatorType");



  const client = useApolloClient();
  let [techSteps, setTechSteps] = useState([]);
  let [stepName, setStepName] = useState([]);
  let [technologyName, setTechnologyName] = useState([]);
  let [tempHetrogeneousJobs, setTempHetrogeneousJobs] = useState([]);
  let [formFields, setFormFields] = useState([]);
  const [, setOverHeadConsumables] =
    useRecoilState(overHeadConsumables);
  let checkArray1 = [];
  let checkArray2 = [];
  let checkArray3 = [];

  let [machine_options, setMachineOptions] = useState([]);
  let [material_options, setMaterialtOptions] = useState([]);
  let [parameters_options, setParametersOptions] = useState([]);
  let [fill_rate_options, setFillRateOptions] = useState([]);
  let [filter_options, setFilterOptions] = useState([]);
  let [recoater_blade_options, setRecoaterBladeOptions] = useState([]);
  let [inert_gas_options, setInertGasOptions] = useState([]);
  let [build_platform_options, setBuildPlatformOptions] = useState([]);

  let [stepFields, setStepFields] = useState([]);
  let [socketObj, setSocketObj] = useState({});
  let [stepId, setStepId] = useState(-1);
  let [technologyId, setTechnologyId] = useState(-1);
  let [socketArray, setSocketArray] = useState([]);
  sessionStorage.setItem("reportStatus", "unsave");

  let [fieldsLoader, setFieldsLoader] = useState(false);
  const [loading, setLoading] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [checkSocketEmit, setSocketEmit] = useState(false);
  const [checkSocketEmitMetal, setSocketEmitMetal] = useState(false);
  // Form Fields of calculators for full simulation of verticle 1 and 2...
  // console.log("formFields check", formFields);
  const [subFieldsByFieldId, setSubFieldsByFieldId] = useState([])
  const [toggleMutation] = useMutation(SAVE_CLIENT_CALCULATIONS);
  const [mutateFunction, { loading: savingCustomizedFieldLoading }] = useMutation(SAVE_CUSTOMIZED_FIELD);
  const [mutateResetCustomizedField, { loading: resettingFieldLoading }] = useMutation(RESET_CUSTOMIZED_FIELD);

  const getStepId = () => {
    if (technology_name === "Polymer") {
      return 2;
    } else {
      return 1;
    }
  };

  React.useEffect(() => {
    setRegionId();
    window.localStorage.setItem("CPP OC", 0);
    setOverHeadConsumables(0);
    const socket = socketIOClient(constant.uri);
    console.log("URL", constant.uri);
    const patch = require("socketio-wildcard")(socketIOClient.Manager);
    patch(socket);
    setSocketObj(socket);
    var technology_id = getStepId();
    setTechnologyId(technology_id);
    let array = formFields;
    array["technology_id"] = { value: parseInt(technologyId), override: false };
    array["step_type_id"] = { value: parseInt(step_type_id), override: false };
    setFormFields({ ...formFields, ...array });
    client
      .query({
        query: constant.GET_STEPS,
        variables: { technology_id, type_id: step_type_id },
      })
      .then((res) => {
        setTechSteps(res.data.getClientSteps);
        setLoading(true);
        setStepName(`${res.data.getClientSteps[0].name}`);
        setStepId(res.data.getClientSteps[0].step_id);
        setTechnologyName(
          `${res.data.getClientSteps[0].technology_id.name} ${res.data.getClientSteps[0].step_type_id.name}`
        );
        client
          .query({
            query: constant.GET_STEPS_FIELDS,
            variables: { step_id: techSteps[0].step_id },
          })
          .then((res) => {
            for (
              let index = 0;
              index < res.data.getFieldsByStepId.length;
              index++
            ) {
              res.data.getFieldsByStepId[index].loader = false;
            }
            let array = res.data.getFieldsByStepId;
            setStepFields(array);
            checkOptionalFields(array);
            let techStepsArr = techSteps;
            // Tech steps used in full simulation for metal and polymer...
            // console.log("Tech Steps", techStepsArr);
            techStepsArr[0].fields = array;

            sessionStorage.setItem("stepsAndFields", JSON.stringify(techSteps));
            getFieldValues(null, false);
            setLoading(true);
            if (sessionStorage.getItem("stepId") && sessionStorage.getItem("calculator_values_default")) {
              handlestep(parseInt(sessionStorage.getItem("stepId")))
            }
          })
          .catch((error) => {
            console.log(error);
            if (
              error.graphQLErrors[0].extensions.exception.response.status ===
              401
            ) {
              toast.error(
                error.graphQLErrors[0].extensions.exception.response.message
              );
              window.location.href = "/";
            }
          });
      })
      .catch((error) => {
        // console.log(error);
        // if (error.graphQLErrors[0].extensions.exception.response.status === 401) {
        //     toast.error(error.graphQLErrors[0].extensions.exception.response.message)
        //     window.location.href = '/';
        // }
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [techSteps]);
  //   console.log("stepFields ", stepFields);

  // Optional Fields Part Names, Company Names, Application...

  React.useEffect(() => {
    if (sessionStorage.getItem("calculator_values_default") && !checkSocketEmit) {
      let autoFlag = false;
      for (let index = 0; index < stepFields.length; index++) {
        if (
          stepFields[index].field_input_type === "dropdown" &&
          stepFields[index].keys !== "batch_filling_grade"
        ) {
          autoFlag = false;
          break;
        }
        if (
          stepFields[index].field_input_type === "textfield" &&
          stepFields[index].keys !== "other_post_processing_cost_per_part"
        ) {
          autoFlag = false;
          break;
        }
        autoFlag = true;
      }
      let optionalFields = stepFields.filter(x => x.is_optional === true);
      let constantFields = stepFields.filter(x => x.field_type === "constant");
      let resultFields = [];
      if (optionalFields.length > 0 && constantFields.length > 0) {
        resultFields = optionalFields.concat(constantFields);
      } else if (optionalFields.length > 0) {
        resultFields = optionalFields;
      } else if (constantFields.length > 0) {
        resultFields = constantFields;
      } else {
        resultFields = []; // If both arrays are empty, resultFields will be an empty array
      }
      if (resultFields && resultFields.length) {
        const formFieldsKeys = Object.keys(formFields)
        for (let index = 0; index < resultFields.length; index++) {
          if (formFieldsKeys.includes(`${resultFields[index].keys}`)) {
            autoFlag = true
          } else {
            autoFlag = false
          }
        }

        if (autoFlag) {
          if (stepId !== 45) {
            getFieldValues(null, false);
          }
          setSocketEmit(true)
        }
        setFieldsLoader(false);
        setLoading(true);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formFields])

  React.useEffect(() => {
    if (build_platform_options.length && !checkSocketEmitMetal) {
      getFieldValues(null, false);
      setSocketEmitMetal(true)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [build_platform_options])


  const checkOptionalFields = (array) => {
    const formFieldsObj = formFields;
    for (let index = 0; index < array.length; index++) {
      if (array[index].is_optional) {
        // console.log("Array Index Names", array[index].name);
        if (array[index].name === "Application") {
        }
        if (array[index].field_type === "constant") {
          formFieldsObj[array[index].keys] = {
            value: array[index].data_reference,
            override: false,
          };
        } else if (array[index].field_data_type !== "string") {
          formFieldsObj[array[index].keys] = { value: 0, override: false };
        } else {
          formFieldsObj[array[index].keys] = { value: "", override: false };
        }
      } else {
        if (array[index].field_data_type !== "string") {
          formFieldsObj[array[index].keys] = { value: 0, override: false };
        }
      }
    }
    setFormFields({ ...formFields, ...formFieldsObj });
    // console.log("Check Form Option Fields", formFields);
  };

  const setRegionId = () => {
    const currencies = JSON.parse(sessionStorage.getItem("all_currencies"));
    // console.log("Getting Currencies from session", currencies);
    const currency = sessionStorage.getItem("currency");
    // console.log("Getting Currency from Session", currency);
    const obj = currencies.find((x) => x.name === currency);
    let array = formFields;
    array["region_id"] = { value: parseInt(obj.region_id), override: false };
    setFormFields({ ...formFields, ...array });
    // console.log("Set Form in Set Region Id", formFields);
  };

  const handlestep = (stepIdVar) => {
    if (stepIdVar > stepId && !parseInt(sessionStorage.getItem("stepId"))) {
      return;
    }
    setStepId(stepIdVar);
    setFieldsLoader(true);
    // Handling Dynamically Active States of Steps
    const divs = document.getElementsByClassName("progress-stage");
    for (let index = 0; index < divs.length; index++) {
      divs[index].classList.remove("arrow-back");
    }

    for (let index = 0; index < stepFields.length; index++) {
      if (
        document.getElementById(`edit-${stepFields[index].field_id}`) != null
      ) {
        document
          .getElementById(`edit-${stepFields[index].field_id}`)
          .classList.remove("hidden");
      }
      if (
        document.getElementById(`cross-${stepFields[index].field_id}`) != null
      ) {
        document
          .getElementById(`cross-${stepFields[index].field_id}`)
          .classList.add("hidden");
      }
      if (
        document.getElementById(`div-${stepFields[index].field_id}`) != null
      ) {
        document
          .getElementById(`div-${stepFields[index].field_id}`)
          .classList.add("hidden");
      }
      if (
        document.getElementById(`input-${stepFields[index].field_id}`) != null
      ) {
        document.getElementById(
          `input-${stepFields[index].field_id}`
        ).value = 0;
      }
    }

    document.getElementById(stepIdVar).classList.add("arrow-back");
    const flags = document.getElementsByClassName(`flag-class`);
    for (let index = 0; index < flags.length; index++) {
      flags[index].classList.remove("flag-next");
    }
    document.getElementById(`flag-${stepIdVar}`).classList.add("flag-next");
    // End

    //Handling Selected and Unselected Icons
    const unSelectedTechSteps = techSteps.filter(
      (x) => x.step_id !== stepIdVar
    );
    for (let index = 0; index < unSelectedTechSteps.length; index++) {
      document.getElementById(
        `image-${unSelectedTechSteps[index].step_id}`
      ).src = unSelectedTechSteps[index].unselected_icon;
    }
    const selectedIcon = techSteps.find((x) => x.step_id === stepIdVar);
    document.getElementById(`image-${stepIdVar}`).src =
      selectedIcon.selected_icon;
    //End
    setStepName(`${selectedIcon.name}`);
    setTechnologyName(
      `${techSteps[0].technology_id.name} ${techSteps[0].step_type_id.name}`
    );
    // Getting Fields by Step Id
    client
      .query({
        query: constant.GET_STEPS_FIELDS,
        variables: { step_id: stepIdVar },
      })
      .then((res) => {
        setSocketArray([]);
        for (
          let index = 0;
          index < res.data.getFieldsByStepId.length;
          index++
        ) {
          res.data.getFieldsByStepId[index].loader = false;
        }
        // TODO Ye logic Replicate krni hai
        if (tempHetrogeneousJobs.length > 0) {
          const indexCpp = res.data.getFieldsByStepId.findIndex(
            (x) =>
              x.field_input_type === "multiple_labels" && x.keys === "cpp_part"
          );
          if (indexCpp !== -1) {
            const length =
              technologyId === 1
                ? tempHetrogeneousJobs.length / 3
                : tempHetrogeneousJobs.length / 2;
            for (let index = 0; index < length; index++) {
              const existMultipleLabel = res.data.getFieldsByStepId.find(
                (x) => x.field_input_type === "multiple_labels"
              );
              if (existMultipleLabel) {
                let obj = Object.assign({}, existMultipleLabel);
                res.data.getFieldsByStepId.splice(indexCpp + index, 0, obj);
              }
            }
            for (let index = 0; index < length + 1; index++) {
              res.data.getFieldsByStepId[indexCpp + index].name = `CPP Part ${index + 1
                }`;
              res.data.getFieldsByStepId[indexCpp + index].keys = `cpp_part_${index + 1
                }`;
            }
          }
        }

        stepFields = res.data.getFieldsByStepId;
        setStepFields(stepFields);
        const recurring_expense = stepFields?.filter((x) => x.keys === FIELD_NAMES?.RECURRING_EXPENSE)
        if (!!recurring_expense && !!recurring_expense.length) {
          getSubFieldsById(recurring_expense?.[0].field_id)
        }
        const stepsAndFields = JSON.parse(
          sessionStorage.getItem("stepsAndFields")
        );
        const findIndexVar = stepsAndFields.findIndex(
          (x) => x.step_id === stepId
        );
        if (findIndexVar !== -1) {
          stepsAndFields[findIndexVar].fields = res.data.getFieldsByStepId;
        }
        sessionStorage.setItem(
          "stepsAndFields",
          JSON.stringify(stepsAndFields)
        );

        if (!sessionStorage.getItem("calculator_values_default")) {
          let formFieldsObj = formFields;
          let optionalFields = stepFields.filter((x) => x.is_optional === true);
          for (let index = 0; index < optionalFields.length; index++) {
            if (optionalFields[index].field_type === "constant") {
              formFieldsObj[optionalFields[index].keys] = {
                value: stepFields[index].data_reference,
                override: false,
              };
            } else if (optionalFields[index].field_data_type !== "string") {
              formFieldsObj[optionalFields[index].keys] = {
                value: 0,
                override: false,
              };
            } else {
              formFieldsObj[optionalFields[index].keys] = {
                value: "",
                override: false,
              };
            }
          }
          setFormFields({ ...formFields, ...formFieldsObj });
          let autoFlag = false;
          for (let index = 0; index < stepFields.length; index++) {
            if (
              stepFields[index].field_input_type === "dropdown" &&
              stepFields[index].keys !== "batch_filling_grade"
            ) {
              autoFlag = false;
              break;
            }
            if (
              stepFields[index].field_input_type === "textfield" &&
              stepFields[index].keys !== "other_post_processing_cost_per_part"
            ) {
              autoFlag = false;
              break;
            }
            autoFlag = true;
          }

          let constantFields = stepFields.filter(
            (x) => x.field_type === "constant"
          );
          if (constantFields.length) {
            // Constant field of full simualation...
            // console.log("Constant Fields");
            let array = formFields;
            for (let index = 0; index < constantFields.length; index++) {
              array[constantFields[index].keys] = {
                value: constantFields[index].data_reference,
                override: false,
              };
            }
            setFormFields({ ...formFields, ...array });
          }
          if (autoFlag && stepId !== 45) {
            getFieldValues(null, false);
          }
          if (!Object.keys(formFields).includes('calculator_type') && !Object.keys(formFields).includes('user_id')) {
            let formFieldsObj = formFields;
            formFieldsObj['calculator_type'] = {
              value: calculatorType,
              override: false,
            }
            formFieldsObj['user_id'] = {
              value: sessionStorage.getItem("user_id"),
              override: false,
            }
            setFormFields({ ...formFields, ...formFieldsObj });
          }
          setSocketEmitMetal(false)
          setFieldsLoader(false);
          setLoading(true);
        }
        else {
          let formFieldsObj = JSON.parse(sessionStorage.getItem("calculator_values_default"));
          let optionalFields = stepFields.filter(x => x.is_optional === true);
          let constantFields = stepFields.filter(x => x.field_type === "constant");

          let resultFields = [];
          if (optionalFields.length > 0 && constantFields.length > 0) {
            resultFields = optionalFields.concat(constantFields);
          } else if (optionalFields.length > 0) {
            resultFields = optionalFields;
          } else if (constantFields.length > 0) {
            resultFields = constantFields;
          } else {
            resultFields = []; // If both arrays are empty, resultFields will be an empty array
          }
          if (resultFields && resultFields.length) {
            for (let index = 0; index < resultFields.length; index++) {
              if (resultFields[index].field_type === "constant") {
                formFieldsObj[resultFields[index].keys] = {
                  value: resultFields[index].data_reference,
                  override: false,
                };
              } else if (resultFields[index].field_data_type !== "string") {
                formFieldsObj[resultFields[index].keys] = {
                  value: 0,
                  override: false,
                };
              } else {
                formFieldsObj[resultFields[index].keys] = {
                  value: "",
                  override: false,
                };
              }
            }
            setSocketEmit(false)
            setSocketEmitMetal(false)
            setFormFields({ ...formFields, ...formFieldsObj });
          }
        }
      })
      .catch((error) => {
        // toast.error(error.graphQLErrors[0].messasge.message)
      });

    //
  };
  const unRegisterSocketEvents = () => {
    socketObj.off("*");
  };

  const emitSocket = (key) => {
    const fieldObj = stepFields.find((x) => x.formula === key);
    if (
      fieldObj &&
      key !== "FOBV-Single-Multi" &&
      fieldObj &&
      key !== "FO-Total-PV"
    ) {
      const formula_inputs_array = fieldObj.formula_inputs.split(",");
      let obj = {};
      for (let index = 0; index < formula_inputs_array.length; index++) {
        if (
          formFields[formula_inputs_array[index].trim()] &&
          formula_inputs_array[index].trim() !== "hetrojobs" &&
          formFields[formula_inputs_array[index].trim()] !== -1
        ) {
          obj[formula_inputs_array[index].trim()] = formula_inputs_array[index].trim() === "calculator_type" ? formFields[formula_inputs_array[index].trim()].value : parseFloat(
            formFields[formula_inputs_array[index].trim()].value
          );
        } else if (formFields[formula_inputs_array[index].trim()]) {
          obj[formula_inputs_array[index].trim()] =
            formFields[formula_inputs_array[index].trim()];
        }
        else if (formula_inputs_array[index].trim() === "is_customizable") {
          obj[formula_inputs_array[index].trim()] = fieldObj?.is_customizable
        }
        else if (formula_inputs_array[index].trim() === "field_id") {
          obj[formula_inputs_array[index].trim()] = fieldObj?.field_id
        }
      }
      if (Object.keys(obj).length === formula_inputs_array.length) {
        const fieldIndex = stepFields.findIndex((x) => x.formula === key);
        if (fieldIndex !== -1) {
          let array = stepFields;
          array[fieldIndex].loader = true;
          setStepFields([...array]);
        }
        if (key === 'FO-CPPOC2') {
          socketObj.emit("calculator", { key: key, input: obj, calculator_type: calculatorType });
        }
        else {
          socketObj.emit("calculator", { key: key, input: obj });
        }
      } else {
        const fieldIndex = stepFields.findIndex((x) => x.formula === key);
        if (fieldIndex !== -1) {
          let array = stepFields;
          array[fieldIndex].loader = false;
          setStepFields([...array]);
        }
        const findIndex = socketArray.findIndex((x) => x.formula === key);
        if (findIndex !== -1 && findIndex + 1 !== socketArray.length) {
          const fieldIndex = stepFields.findIndex(
            (x) => x.formula === socketArray[findIndex + 1].formula
          );
          if (fieldIndex !== -1) {
            let array = stepFields;
            array[fieldIndex].loader = true;
            setStepFields([...array]);
          }
          emitSocket(socketArray[findIndex + 1].formula);
        }
      }
    } else if (
      (fieldObj &&
        fieldObj.keys === "build_volume" &&
        formFields["step_type_id"].value === 2) ||
      (fieldObj &&
        fieldObj.keys === "total_part_volume" &&
        formFields["step_type_id"].value === 2)
    ) {
      const formula_inputs_array = fieldObj.formula_inputs.split(",");
      let array = [];
      let hetroFlag = false;

      if (formFields[constant.HETROGENEOUS]) {
        for (
          let index = 0;
          index < formFields[constant.HETROGENEOUS].length;
          index++
        ) {
          for (let jindex = 0; jindex < formula_inputs_array.length; jindex++) {
            if (
              !formFields[constant.HETROGENEOUS][index][
              formula_inputs_array[jindex].trim()
              ]
            ) {
              hetroFlag = true;
              break;
            }
          }
          if (hetroFlag) {
            break;
          } else {
            array.push(formFields[constant.HETROGENEOUS][index]);
          }
        }
        if (array.length === formFields[constant.HETROGENEOUS].length) {
          const fieldIndex = stepFields.findIndex((x) => x.formula === key);
          if (fieldIndex !== -1) {
            let array = stepFields;
            array[fieldIndex].loader = true;
            setStepFields([...array]);
          }
          socketObj.emit("calculator", { key: key, input: array });
        } else {
          const fieldIndex = stepFields.findIndex((x) => x.formula === key);
          if (fieldIndex !== -1) {
            let array = stepFields;
            array[fieldIndex].loader = false;
            setStepFields([...array]);
          }
          const findIndex = socketArray.findIndex((x) => x.formula === key);
          if (findIndex !== -1 && findIndex + 1 !== socketArray.length) {
            const fieldIndex = stepFields.findIndex(
              (x) => x.formula === socketArray[findIndex + 1].formula
            );
            if (fieldIndex !== -1) {
              let array = stepFields;
              array[fieldIndex].loader = true;
              setStepFields([...array]);
            }
            emitSocket(socketArray[findIndex + 1].formula);
          }
        }
      }
    } else {
      console.log("Not Emitting", fieldObj);
    }
  };

  const renderSteps = () => {
    return techSteps.map((steps, index) => {

      return (
        <>
          <div
            id={steps.step_id}
            key={steps.name}
            data-tip={steps.description}
            className={
              "col p-0 progress-stage deadcenter " +
              (index === 0 ? "arrow-back" : "")
            }
            onClick={() => {
              handlestep(steps.step_id);
            }}
          >
            <div
              className={
                "stage " +
                (steps.name === "Overhead & Consumable" ? "min-width" : "")
              }
            >
              <img
                alt=""
                className="icon-image"
                id={"image-" + steps.step_id}
                src={index === 0 ? steps.selected_icon : steps.unselected_icon}
                width="24"
                height="20"
              />
              <p className="d-flex">
                {steps.name}
                {/* {step_type_id === 1 && (steps.step_id === 48 || steps.step_id === 40) && !Access && <a href="/premium-request" className="premium-row-text py-1" >Premium</a>} */}
              </p>
            </div>
          </div>
          <div
            id={"flag-" + steps.step_id}
            className={"flag-class " + (index === 0 ? "flag-next" : "")}
          ></div>
        </>
      );
    });
  };
  const getSubFieldsById = (fieldId) => {
    client
      .query({
        query: constant.GET_SUB_FIELDS_BY_ID,
        variables: { field_id: fieldId },
      })
      .then((res) => {
        let array = formFields;
        for (let index = 0; index < res?.data?.getSubFieldsByFieldId.length; index++) {
          array[res?.data?.getSubFieldsByFieldId[index]?.name?.toLowerCase()?.replace(" ", "_")] = { value: 0, override: true }

        }
        setFormFields({ ...formFields, ...array });
        setSubFieldsByFieldId(res?.data?.getSubFieldsByFieldId)
      })
      .catch((error) => {
        console.log(error);
      });
  }
  const addMoreJobs = (technologyName, traversalIndex) => {
    const calculatedFields = [];
    if (technologyName === "Polymer Simulation") {
      const limitIndex = traversalIndex - 2;
      for (let index = limitIndex; index < traversalIndex; index++) {
        calculatedFields.push(stepFields[index]);
      }
      const array = [...tempHetrogeneousJobs, ...calculatedFields];
      setTempHetrogeneousJobs(array);
    } else {
      const limitIndex = traversalIndex - 3;
      for (let index = limitIndex; index < traversalIndex; index++) {
        calculatedFields.push(stepFields[index]);
      }
      const array = [...tempHetrogeneousJobs, ...calculatedFields];
      setTempHetrogeneousJobs(array);
    }
  };

  const openEditBox = (fieldId, flag, fieldKey = "") => {
    if (fieldKey === FIELD_NAMES?.RECURRING_EXPENSE) {
      document.getElementById(`row-spaceOne-${fieldId}`).classList.remove("hidden");
      document.getElementById(`row-spaceTwo-${fieldId}`).classList.remove("hidden");
      document.getElementById(`row-spaceThree-${fieldId}`).classList.remove("hidden");
      document.getElementById(`row-spaceFour-${fieldId}`).classList.remove("hidden");
      document.getElementById(`row-spaceFive-${fieldId}`).classList.remove("hidden");
      document.getElementById(`row-spaceSix-${fieldId}`).classList.remove("hidden");
    }
    if (flag) {
      document.getElementById(`edit-${fieldId}`).classList.add("hidden");
      document.getElementById(`cross-${fieldId}`).classList.remove("hidden");
      document.getElementById(`div-${fieldId}`).classList.remove("hidden");
      if (document.getElementById(`save-${fieldId}`) && document.getElementById(`restore-${fieldId}`)) {
        document.getElementById(`restore-${fieldId}`).classList.remove("hidden");
        document.getElementById(`save-${fieldId}`).classList.remove("hidden");
      }
      document.getElementById(`input-${fieldId}`) && document.getElementById(`input-${fieldId}`).classList.remove("hidden");
    } else {
      document.getElementById(`edit-${fieldId}`).classList.remove("hidden");
      document.getElementById(`cross-${fieldId}`).classList.add("hidden");
      document.getElementById(`div-${fieldId}`).classList.add("hidden");
      if (fieldKey === FIELD_NAMES?.RECURRING_EXPENSE) {
        document.getElementById(`row-spaceOne-${fieldId}`).classList.add("hidden");
        document.getElementById(`row-spaceTwo-${fieldId}`).classList.add("hidden");
        document.getElementById(`row-spaceThree-${fieldId}`).classList.add("hidden");
        document.getElementById(`row-spaceFour-${fieldId}`).classList.add("hidden");
        document.getElementById(`row-spaceFive-${fieldId}`).classList.add("hidden");
        document.getElementById(`row-spaceSix-${fieldId}`).classList.add("hidden");
        const arr = formFields
        arr[FIELD_NAMES?.SERVICE_EXPENSE] = { value: 0, override: false }
        arr[FIELD_NAMES?.MISCELLANEOUS] = { value: 0, override: false }
        arr[FIELD_NAMES?.SOFTWARE_EXPENSE] = { value: 0, override: false }
        setFormFields({ ...formFields, ...arr })
      }
      if (document.getElementById(`save-${fieldId}`) && document.getElementById(`restore-${fieldId}`)) {
        document.getElementById(`save-${fieldId}`).classList.add("hidden");
        document.getElementById(`restore-${fieldId}`).classList.add("hidden");
      }
      document.getElementById(`input-${fieldId}`) && document.getElementById(`input-${fieldId}`).classList.add("hidden");
      const fieldObj = stepFields.find((x) => x.field_id === fieldId);
      if (fieldObj) {
        if (formFields[fieldObj.keys]) {
          let array = formFields;
          array[fieldObj.keys].override = false;
          setFormFields({ ...formFields, ...array });
          getFieldValues(null, false);
        }
      }
    }
  };

  const renderSubscript = (unit) => {
    if (unit === "cm3") {
      return (
        <span className="subscript-span">
          <var>
            cm<sup>3</sup>
          </var>
        </span>
      );
    } else if (unit === "mm") {
      return (
        <span className="subscript-span">
          <var>mm</var>
        </span>
      );
    } else if (unit === "currency") {
      const currencies = JSON.parse(sessionStorage.getItem("all_currencies"));
      const currency = sessionStorage.getItem("currency");
      const obj = currencies.find((x) => x.name === currency);
      if (obj) {
        return <span className="subscript-span">{obj.currency_symbol}</span>;
      } else {
        return <></>;
      }
    } else if (unit === "g/cm3") {
      return (
        <span className="subscript-span">
          <var>
            g/cm<sup>3</sup>
          </var>
        </span>
      );
    } else if (unit === "year" || unit === "years") {
      return <span className="subscript-span">year</span>;
    } else if (unit === "%") {
      return <span className="subscript-span">%</span>;
    } else if (unit === "hours" || unit === "hour") {
      return <span className="subscript-span">hours</span>;
    } else if (unit === "hours/year") {
      return <span className="subscript-span">hours/year</span>;
    } else if (unit === "μm") {
      return <span className="subscript-span">μm</span>;
    } else if (unit === "mm/h") {
      return <span className="subscript-span">mm/h</span>;
    } else if (unit === "kg") {
      return <span className="subscript-span">kg</span>;
    } else if (unit === "m2") {
      return (
        <span className="subscript-span">
          <var>
            m<sup>2</sup>
          </var>
        </span>
      );
    } else if (unit === "currency/m2") {
      const currencies = JSON.parse(sessionStorage.getItem("all_currencies"));
      const currency = sessionStorage.getItem("currency");
      const obj = currencies.find((x) => x.name === currency);
      if (obj) {
        return (
          <span className="subscript-span">
            <var>
              {obj.currency_symbol}/m<sup>2</sup>
            </var>
          </span>
        );
      } else {
        return <></>;
      }
    } else if (unit === "currency/job") {
      const currencies = JSON.parse(sessionStorage.getItem("all_currencies"));
      const currency = sessionStorage.getItem("currency");
      const obj = currencies.find((x) => x.name === currency);
      if (obj) {
        return (
          <span className="subscript-span">
            <var>{obj.currency_symbol}/job</var>
          </span>
        );
      } else {
        return <></>;
      }
    } else if (unit === "currency/kg") {
      const currencies = JSON.parse(sessionStorage.getItem("all_currencies"));
      const currency = sessionStorage.getItem("currency");
      const obj = currencies.find((x) => x.name === currency);
      if (obj) {
        return (
          <span className="subscript-span">
            <var>{obj.currency_symbol}/kg</var>
          </span>
        );
      } else {
        return <></>;
      }
    } else if (unit === "currency/kW") {
      const currencies = JSON.parse(sessionStorage.getItem("all_currencies"));
      const currency = sessionStorage.getItem("currency");
      const obj = currencies.find((x) => x.name === currency);
      if (obj) {
        return (
          <span className="subscript-span">
            <var>{obj.currency_symbol}/kW</var>
          </span>
        );
      } else {
        return <></>;
      }
    } else if (unit === "kW") {
      return <span className="subscript-span">kW</span>;
    } else if (unit === "m3") {
      return (
        <span className="subscript-span">
          <var>
            m<sup>3</sup>
          </var>
        </span>
      );
    } else if (unit === "m3/job") {
      return (
        <span className="subscript-span">
          <var>
            m<sup>3</sup>/job
          </var>
        </span>
      );
    } else if (unit === "cm3/h") {
      return (
        <span className="subscript-span">
          <var>
            cm<sup>3</sup>/h
          </var>
        </span>
      );
    } else if (unit === "currency/m3") {
      const currencies = JSON.parse(sessionStorage.getItem("all_currencies"));
      const currency = sessionStorage.getItem("currency");
      const obj = currencies.find((x) => x.name === currency);
      if (obj) {
        return (
          <span className="subscript-span">
            <var>
              {obj.currency_symbol}/m<sup>3</sup>
            </var>
          </span>
        );
      } else {
        return <></>;
      }
    } else if (unit === "currency/cm3") {
      const currencies = JSON.parse(sessionStorage.getItem("all_currencies"));
      const currency = sessionStorage.getItem("currency");
      const obj = currencies.find((x) => x.name === currency);
      if (obj) {
        return (
          <span className="subscript-span">
            <var>
              {obj.currency_symbol}/cm<sup>3</sup>
            </var>
          </span>
        );
      } else {
        return <></>;
      }
    } else if (unit === "currency/year") {
      const currencies = JSON.parse(sessionStorage.getItem("all_currencies"));
      const currency = sessionStorage.getItem("currency");
      const obj = currencies.find((x) => x.name === currency);
      if (obj) {
        return (
          <span className="subscript-span">
            <var>{obj.currency_symbol}/year</var>
          </span>
        );
      } else {
        return <></>;
      }
    } else if (unit === "currency/hour" || unit === "currency/h") {
      const currencies = JSON.parse(sessionStorage.getItem("all_currencies"));
      const currency = sessionStorage.getItem("currency");
      const obj = currencies.find((x) => x.name === currency);
      if (obj) {
        return (
          <span className="subscript-span">
            <var>{obj.currency_symbol}/hr</var>
          </span>
        );
      } else {
        return <></>;
      }
    } else if (unit === "h") {
      return <span className="subscript-span">h</span>;
    } else if (unit === "jobs") {
      return <span className="subscript-span">jobs</span>;
    }
  };

  const renderRetroJobs = () => {
    let flagCounter = 0;
    return (
      // eslint-disable-next-line
      tempHetrogeneousJobs.map((hetroJobs, index) => {
        if (technologyName === "Polymer Simulation") {
          if (index % 2 === 0) {
            flagCounter += 1;
          }
          return (
            <tr
              key={hetroJobs.name}
              className={index % 2 === 0 ? "upper-hr" : ""}
            >
              <td>
                <div className="field">
                  <p data-tip={hetroJobs.field_info}>
                    {flagCounter + 1}. {hetroJobs.name}
                  </p>
                  {renderTooltip(hetroJobs)}
                </div>
              </td>
              <td></td>
              <td>
                <div data-tip={hetroJobs.field_info} className="vcenter">
                  <div className="table-inputbox">
                    <input
                      type="text"
                      className="login-input"
                      name={hetroJobs.keys}
                      id={flagCounter}
                      value={
                        formFields[constant.HETROGENEOUS] &&
                          formFields[constant.HETROGENEOUS][flagCounter] !==
                          undefined
                          ? formFields[constant.HETROGENEOUS][flagCounter][
                          hetroJobs.keys
                          ]
                          : ""
                      }
                      onChange={(e) => {
                        getFieldValues(e, true, true);
                      }}
                    />
                    {renderSubscript(hetroJobs.field_unit)}
                  </div>
                </div>
              </td>
            </tr>
          );
        } else {
          if (index % 3 === 0) {
            flagCounter += 1;
          }
          return (
            <tr
              key={hetroJobs.name}
              className={index % 3 === 0 ? "upper-hr" : ""}
            >
              <td>
                <div className="field">
                  <p data-tip={hetroJobs.field_info}>
                    {flagCounter + 1}. {hetroJobs.name}
                  </p>
                  {renderTooltip(hetroJobs)}
                </div>
              </td>
              <td></td>
              <td>
                <div data-tip={hetroJobs.field_info} className="vcenter">
                  <div className="table-inputbox">
                    <input
                      type="text"
                      className="login-input"
                      name={hetroJobs.keys}
                      id={flagCounter}
                      value={
                        formFields[constant.HETROGENEOUS] &&
                          formFields[constant.HETROGENEOUS][flagCounter] !==
                          undefined
                          ? formFields[constant.HETROGENEOUS][flagCounter][
                          hetroJobs.keys
                          ]
                          : ""
                      }
                      onChange={(e) => {
                        getFieldValues(e, true, true);
                      }}
                    />{" "}
                    {renderSubscript(hetroJobs.field_unit)}
                  </div>
                </div>
              </td>
            </tr>
          );
        }
      })
    );
  };

  const renderOptions = (data_reference_type) => {
    if (data_reference_type === "Machine") {
      // Getting Fields by Step Id
      client
        .query({
          query: constant.GET_MACHINES,
          variables: { technology_id: technologyId, calculator_type: calculatorType },
        })
        .then((res) => {
          const options_array = [];
          for (
            let index = 0;
            index < res.data.getClientMachineByTechnology.length;
            index++
          ) {
            options_array.push({
              id: res.data.getClientMachineByTechnology[index].machine_id,
              name: res.data.getClientMachineByTechnology[index].name,
              data_for_cost: res.data.getClientMachineByTechnology[index].is_data_avail_for_cost,
              data_for_carbon: res.data.getClientMachineByTechnology[index].is_data_avail_for_carbon
            });
          }
          setMachineOptions(options_array);
        })
        .catch((error) => {
          // toast.error(error.graphQLErrors[0].messasge.message)
        });
      //
    } else if (data_reference_type === "Material") {
      let group_id = +sessionStorage.getItem("group_id");

      // Getting Fields by Step Id
      client
        .query({
          query: constant.GET_MATERIALS,
          variables: {
            machine_id: parseInt(formFields["machine_id"].value),
            group_id,
            calculator_type: calculatorType
          },
        })
        .then((res) => {
          const options_array = [];
          for (
            let index = 0;
            index < res.data.getClientMaterialByMachineId.length;
            index++
          ) {
            options_array.push({
              id: res.data.getClientMaterialByMachineId[index].material_id,
              name: res.data.getClientMaterialByMachineId[index].name,
              material_data_for_cost: res.data.getClientMaterialByMachineId[index].is_data_avail_for_cost,
              material_data_for_carbon: res.data.getClientMaterialByMachineId[index].is_data_avail_for_carbon,
            });
          }
          setMaterialtOptions(options_array);
        })
        .catch((error) => {
          // toast.error(error.graphQLErrors[0].messasge.message)
        });
      //
    } else if (data_reference_type === "Parameter") {
      // Getting Fields by Step Id
      client
        .query({
          query: constant.GET_PARAMETERS,
          variables: {
            machine_id: parseInt(formFields["machine_id"].value),
            material_id: parseInt(formFields["material_id"].value),
          },
        })
        .then((res) => {
          const options_array = [];
          if (!!res?.data?.getClientParemeterByMMIds?.length) {
            for (
              let index = 0;
              index < res.data.getClientParemeterByMMIds.length;
              index++
            ) {
              if (
                technologyName === "Metal Estimation" ||
                technologyName === "Metal Simulation" ||
                technologyName === "Polymer Simulation"
              ) {
                options_array.push({
                  id: res.data.getClientParemeterByMMIds[index].paremeter_id,
                  name: res.data.getClientParemeterByMMIds[index].name,
                });
              } else {
                options_array.push({
                  core_id: res.data.getClientParemeterByMMIds[index].paremeter_id,
                  id: res.data.getClientParemeterByMMIds[index].name,
                  name: res.data.getClientParemeterByMMIds[index].name,
                });
              }
            }
          }
          else {
            setFieldDataAvailability(true)
          }
          setParametersOptions(options_array);
        })
        .catch((error) => {
          // toast.error(error.graphQLErrors[0].messasge.message)
        });
      //
    } else if (data_reference_type === "Parameter.fill_rate_type") {
      // Getting Fields by Step Id
      client
        .query({
          query: constant.GET_FILL_RATES,
          variables: {
            machine_id: parseInt(formFields["machine_id"].value),
            material_id: parseInt(formFields["material_id"].value),
            parameter_name: formFields["parameter_name"].value,
          },
        })
        .then((res) => {
          const options_array = [];
          for (
            let index = 0;
            index < res.data.getClientFillRates.length;
            index++
          ) {
            options_array.push({
              id: res.data.getClientFillRates[index].paremeter_id,
              fill_rate_type: res.data.getClientFillRates[index].fill_rate_type,
            });
          }
          setFillRateOptions(options_array);
        })
        .catch((error) => {
          // toast.error(error.graphQLErrors[0].messasge.message)
        });
      //
    } else if (data_reference_type === "Filters") {
      // Getting Fields by Step Id
      client
        .query({
          query: constant.GET_FILTERS,
          variables: { machine_id: +formFields.machine_id.value },
        })
        .then((res) => {
          const options_array = [];
          for (
            let index = 0;
            index < res.data.getClientFilters.length;
            index++
          ) {
            options_array.push({
              id: res.data.getClientFilters[index].filter_id,
              name: res.data.getClientFilters[index].name,
            });
          }
          const fields = formFields
          fields['filter_id'] = {
            value: options_array[0].id,
            override: false
          }
          setFormFields({ ...formFields, ...fields })
          setFilterOptions(options_array);
        })
        .catch((error) => {
          // toast.error(error.graphQLErrors[0].messasge.message)
        });
      //
    } else if (data_reference_type === "Recoater_Blade") {
      // Getting Fields by Step Id
      client
        .query({
          query: constant.GET_RECOATER_BLADES,
          variables: { machine_id: +formFields.machine_id.value },
        })
        .then((res) => {
          const options_array = [];
          for (
            let index = 0;
            index < res.data.getClientRacoaterBlades.length;
            index++
          ) {
            options_array.push({
              id: res.data.getClientRacoaterBlades[index].racoater_blade_id,
              name: res.data.getClientRacoaterBlades[index].recoater_blade_name,
            });
          }
          const fields = formFields
          fields['recoater_blade_id'] = {
            value: options_array[0].id,
            override: false
          }
          setFormFields({ ...formFields, ...fields })
          setRecoaterBladeOptions(options_array);
        })
        .catch((error) => {
          // toast.error(error.graphQLErrors[0].messasge.message)
        });
    } else if (data_reference_type === "Inert_Gas") {
      // Getting Fields by Step Id
      client
        .query({
          query: constant.GET_INERT_GAS,
          variables: {},
        })
        .then((res) => {
          const options_array = [];
          for (
            let index = 0;
            index < res.data.getClientInertGas.length;
            index++
          ) {
            options_array.push({
              id: res.data.getClientInertGas[index].gas_id,
              name: res.data.getClientInertGas[index].name,
            });
          }
          const fields = formFields
          fields['select_inert_gas'] = {
            value: options_array[0].id,
            override: false
          }
          setFormFields({ ...formFields, ...fields })
          setInertGasOptions(options_array);
        })
        .catch((error) => {
          // toast.error(error.graphQLErrors[0].messasge.message)
        });
    } else if (data_reference_type === "Build_Platform") {
      // Getting Fields by Step Id
      client
        .query({
          query: constant.GET_BUILD_PLATFORM,
          variables: { machine_id: +formFields.machine_id.value },
        })
        .then((res) => {
          const options_array = [];
          for (
            let index = 0;
            index < res.data.getClientBuildPlatforms.length;
            index++
          ) {
            options_array.push({
              id: res.data.getClientBuildPlatforms[index].build_platform_id,
              name: res.data.getClientBuildPlatforms[index].build_platform_name,
            });
          }
          const fields = formFields
          fields['build_platform_id'] = {
            value: options_array[0].id,
            override: false
          }
          setFormFields({ ...formFields, ...fields })
          setBuildPlatformOptions(options_array);
        })
        .catch((error) => {
          // toast.error(error.graphQLErrors[0].messasge.message)
        });
    }
  };

  const handleSection = () => {
    if (fieldDataAvailability) {
      toast.error(RESPONSE_MESSAGES?.VALUES_NOT_AVAILABLE)
      return
    }
    const fieldsErrors = document.getElementsByClassName("error-messages");
    for (let index = 0; index < fieldsErrors.length; index++) {
      fieldsErrors[index].style.display = "none";
    }
    let array = formFields;
    for (let index = 0; index < stepFields.length; index++) {
      if (
        formFields[stepFields[index].keys] === undefined &&
        stepFields[index].is_optional === false &&
        stepFields[index].field_input_type !== "label_section" &&
        stepFields[index].field_input_type !== "label" &&
        stepFields[index].field_input_type !== "enable_multiple_rows" &&
        stepFields[index].renderer === 2
      ) {
        document.getElementById(
          `error-${stepFields[index].keys}`
        ).style.display = "block";
        return;
      } else if (
        formFields[stepFields[index].keys] === undefined &&
        stepFields[index].is_optional === true
      ) {
        if (stepFields[index].field_data_type !== "string") {
          array[stepFields[index].keys] = { value: 0, override: false };
        } else {
          array[stepFields[index].keys] = { value: "", override: false };
        }
      }
    }
    setFormFields({ ...formFields, ...array });

    handlestep(++stepId);
  };
  const getFieldValues = (
    e,
    flagForChange = true,
    retro = false,
    fieldType = "number",
    override = false,
    notInArray = false,
    isCostOfPowerKW = false
  ) => {
    unRegisterSocketEvents();
    let emptyString = false;
    if (flagForChange) {
      let { name, value } = e.target;
      if (name === "material_id") {
        let result = material_options?.find(material => material.id === +value)
        sessionStorage.setItem("materials", result?.material_data_for_carbon)
      }
      if (name === "machine_id") {
        let result = machine_options?.find(machine => machine.id === +value)
        sessionStorage.setItem("machines", result?.data_for_carbon)
      }
      if (!isCostOfPowerKW) {
        if (
          parseInt(value.toString().charAt(0)) === 0 &&
          !value.toString().includes(".")
        ) {
          if (parseInt(value) === 0) {
            value = 0;
          } else {
            value = value.substring(1, value.length);
          }
        }
      }
      if (fieldType === "number") {
        // Checking field type and value to be fill in main object field...
        // console.log("Field Type number", fieldType);
        // console.log("Value", value);
        if (value === "") {
          value = 0;
        }
        if (isNaN(value)) {
          console.log("Value NAN");
          emptyString = true;
          e.preventDefault();
          return;
        }
      }
      if (retro) {
        const retroObjArray =
          formFields[constant.HETROGENEOUS] === undefined
            ? []
            : formFields[constant.HETROGENEOUS];

        let customRetroObj = retroObjArray.find((x) => x.id === e.target.id);


        if (customRetroObj) {


          customRetroObj["id"] = e.target.id;
          customRetroObj[e.target.name] = e.target.value;
          retroObjArray[retroObjArray.findIndex((x) => x.id === e.target.id)] =
            customRetroObj;
          if (name === "part_volume") {

            let part_total_volume = 0;
            for (let index = 0; index < retroObjArray.length; index++) {
              part_total_volume += parseInt(retroObjArray[index].part_volume);
            }

            let array = formFields;
            array["part_total_volume"] = {
              value: +part_total_volume,
              override: false,
            };
          } else if (name === "no_of_parts") {
            let total_no_of_parts = 0;
            for (let index = 0; index < retroObjArray.length; index++) {
              total_no_of_parts += parseInt(retroObjArray[index].no_of_parts);
            }
            let array = formFields;
            // Total array of current selected tech....
            // console.log("Array in Get field: ", array);
            array["total_no_of_parts"] = {
              value: total_no_of_parts,
              override: false,
            };

            // console.log("Before Set Array in Get field: ", array);
            // console.log("Before Set Array in Get field: ", formFields);

            setFormFields({ ...formFields, ...array });
            // console.log(" After Array in Get field: ", array);
            // console.log("After Set Array in Get field: ", formFields);
          }
          else if (name === "part_support_volume") {
            let total_part_support_volume = 0;
            for (let index = 0; index < retroObjArray.length; index++) {
              total_part_support_volume += parseInt(retroObjArray[index].part_support_volume);
            }
            let array = formFields;
            // Total array of current selected tech....
            // console.log("Array in Get field: ", array);
            array["total_part_support_volume"] = {
              value: total_part_support_volume,
              override: false,
            };

            // console.log("Before Set Array in Get field: ", array);
            // console.log("Before Set Array in Get field: ", formFields);

            setFormFields({ ...formFields, ...array });
            // console.log(" After Array in Get field: ", array);
            // console.log("After Set Array in Get field: ", formFields);
          }
        } else {
          customRetroObj = {};
          customRetroObj["id"] = e.target.id;
          customRetroObj[e.target.name] = e.target.value;
          retroObjArray.push(customRetroObj);
          if (name === "part_volume") {
            let array = formFields;
            array["part_total_volume"] = {
              value: +e.target.value,
              override: false,
            };
            setFormFields({ ...formFields, ...array });
          } else if (name === "no_of_parts") {
            let array = formFields;
            array["total_no_of_parts"] = {
              value: e.target.value,
              override: false,
            };
            setFormFields({ ...formFields, ...array });
          }
        }
        let array = formFields;
        array[constant.HETROGENEOUS] = retroObjArray;
        if (notInArray) {
          array[name] =
            name !== "part_name" &&
              name !== "company_name" &&
              name !== "parameter_name"
              ? { value: value, override: true }
              : { value, override: true };
        }
        setFormFields({ ...formFields, ...array });
      } else if (override) {
        // OverRide able fields....
        // console.log("Over Rides");
        if (value !== "" && value !== undefined && value.length !== 0) {
          let array = formFields;

          array[name] =
            name !== "part_name" &&
              name !== "company_name" &&
              name !== "parameter_name"
              ? { value: value, override: true }
              : { value, override: true };
          setFormFields({ ...formFields, ...array });
          if (name === FIELD_NAMES?.MISCELLANEOUS || name === FIELD_NAMES?.SERVICE_EXPENSE || name === FIELD_NAMES?.SOFTWARE_EXPENSE) {
            const recurring_expense = parseInt(array[FIELD_NAMES?.SERVICE_EXPENSE].value) + parseInt(array[FIELD_NAMES?.SOFTWARE_EXPENSE].value) + parseInt(array[FIELD_NAMES?.MISCELLANEOUS].value)
            array[FIELD_NAMES?.RECURRING_EXPENSE] = { value: recurring_expense, override: true }
            setFormFields({ ...formFields, ...array });
          }
          if (sessionStorage.getItem('technologyName') === 'Metal (Estimation)') {
            let array = formFields;
            if (name === "no_of_parts") {
              array["total_no_of_parts"] = { value: value, override: false }
              setFormFields({ ...formFields, ...array });
            }
          }
        }
      } else {
        if (value !== "" && value !== undefined && value.length !== 0) {
          let array = formFields;
          array[name] =
            name !== "part_name" &&
              name !== "company_name" &&
              name !== "parameter_name"
              ? { value: value, override: false }
              : { value, override: false };
          if (name === "machine_id") {
            renderOptions("Material");
            formFields["material_id"] = -1;
            formFields["parameter_id"] = -1;
          }
          if (name === "material_id") {
            renderOptions("Parameter");
            formFields["parameter_id"] = -1;
          }
          if (name === "parameter_name") {
            renderOptions("Parameter.fill_rate_type");
            const obj = parameters_options.find((x) => x.name === value);
            formFields["parameter_id"] = {
              override: false,
              value: obj.core_id,
            };
          }
          setFormFields({ ...formFields, ...array });
        } else {
          if (name === "part_name") {
            formFields["part_name"] = "";
          }
          if (name === "company_name") {
            formFields["company_name"] = "";
          }
        }
      }
      if (!emptyString) {
        registerSocketEvents();

        const filteredFields = stepFields.filter(
          (x) => x.field_type === "database" || x.field_type === "calculated"
        );
        // Step Fields after Register Socket
        // console.log("Step Fields after Register Socket", filteredFields);

        computeFields(filteredFields, override);
      }
    } else {
      registerSocketEvents();
      const filteredFields = stepFields.filter(
        (x) => x.field_type === "database" || x.field_type === "calculated"
      );
      computeFields(filteredFields, override);
    }
  };

  // console.log("GET FIELD VALUE: ", getFieldValues());

  const registerSocketEvents = () => {
    socketObj.on("connect", () => {
      console.log("Connected Socket in Register Socket Event");
    });
    socketObj.on("disconnect", () => {
      console.log("disconnect Socket in Register Socket Event");
    });
    socketObj.on("*", (event, data) => {
      if (event && event.data && event.data.length > 0) {
        const fieldObj = stepFields.find((x) => x.formula === event.data[0]);
        if (
          event.data[1] &&
          event.data[1].data &&
          event.data[1].data.type === "FIELD_CHANGE"
        ) {
          const index = stepFields.findIndex(
            (x) => x.field_id === parseInt(event.data[1].data.data)
          );
          if (index !== -1) {
            let array = stepFields;
            let array_index = array.findIndex(
              (x) => x.formula === event.data[1].data.previous_formula
            );
            if (array_index !== -1) {
              array[array_index].formula = event.data[1].data.formula;
              array[array_index].formula_inputs =
                event.data[1].data.formula_inputs;
              array[array_index].loader = false;
            }
            array[index].renderer = 2;
            setStepFields([...array]);
            // fields filled with values...
            // console.log("Step Fields", array);
          }
        } else {
          if (
            event.data[0] === "FO-CPP-HETRO" ||
            event.data[0] === "FO-CPP-HETRO2"
          ) {
            let array = formFields;
            if (!array["cpp_part"]?.override) {
              if (event?.data[1]?.data?.value?.length === 1) {
                array["cpp_part_1"] = {
                  value: event.data[1].data?.value[0],
                  override: false,
                };
                array["cpp_part"] = {
                  value: event.data[1].data?.value[0],
                  override: false,
                };
              } else {
                for (let index = 0; index < event.data[1].data.value?.length; index++) {
                  array[`cpp_part_${index + 1}`] = {
                    value: event.data[1].data?.value[index],
                    override: false,
                  };
                }
              }
            }
            setFormFields({ ...formFields, ...array });
            const fieldIndex = stepFields.findIndex(
              (x) => x.formula === event.data[0]
            );
            if (fieldIndex !== -1) {
              let array = stepFields;
              array[fieldIndex].loader = false;
              setStepFields([...array]);
            }
            if (socketArray.length > 0) {
              const socketIndex = socketArray.findIndex(
                (x) => x.formula === event.data[0]
              );
              if (socketIndex !== -1) {
                socketArray.splice(socketIndex, 1);
              }
              if (socketArray.length > 0) {
                const fieldIndex = stepFields.findIndex(
                  (x) => x.formula === socketArray[0].formula
                );
                if (fieldIndex !== -1) {
                  let array = stepFields;
                  array[fieldIndex].loader = false;
                  setStepFields([...array]);
                }
                emitSocket(socketArray[0].formula, socketArray[0].data);
              }
            }
          } else if (fieldObj) {
            const fieldIndex = stepFields.findIndex(
              (x) => x.formula === event.data[0]
            );
            if (fieldIndex !== -1) {
              let array = stepFields;
              array[fieldIndex].loader = false;
              setStepFields([...array]);
            }
            if (!formFields[fieldObj.keys] && fieldObj.keys === "no_of_parts") {
              let array = formFields;
              array["no_of_parts"] = {
                value: event.data[1].data?.value,
                override: false,
              };
              array["total_no_of_parts"] = {
                value: event.data[1].data?.value,
                override: false,
              };
              setFormFields({ ...formFields, ...array });
            }
            if (
              fieldObj.keys === "no_of_parts" &&
              formFields[fieldObj.keys] &&
              formFields[fieldObj.keys].override === false
            ) {
              let array = formFields;
              array["no_of_parts"] = {
                value: event.data[1].data?.value,
                override: false,
              };
              array["total_no_of_parts"] = {
                value: event.data[1].data?.value,
                override: false,
              };
              setFormFields({ ...formFields, ...array });
            }
            if (!formFields[fieldObj.keys] && fieldObj.keys === "part_volume") {
              let array = formFields;
              array["part_volume"] = {
                value: event.data[1].data?.value,
                override: false,
              };
              array["total_part_volume"] = {
                value: event.data[1].data?.value,
                override: false,
              };
              setFormFields({ ...formFields, ...array });
            }
            if (
              fieldObj.keys === "part_volume" &&
              formFields[fieldObj.keys] &&
              formFields[fieldObj.keys].override === false
            ) {
              let array = formFields;
              array["part_volume"] = {
                value: event.data[1].data?.value,
                override: false,
              };
              array["total_part_volume"] = {
                value: event.data[1].data?.value,
                override: false,
              };
              setFormFields({ ...formFields, ...array });
            }
            if (!formFields[fieldObj.keys]) {
              let array = formFields;
              if (fieldObj?.is_customizable) {
                array[fieldObj.keys] = {
                  value: event.data[1].data?.value,
                  override: false,
                  field_id: fieldObj?.field_id,
                  is_customizable: fieldObj?.is_customizable,
                };
              }
              else {
                array[fieldObj.keys] = {
                  value: event.data[1].data?.value,
                  override: false,
                };
              }
              setFormFields({ ...formFields, ...array });
            }
            if (
              formFields[fieldObj.keys] &&
              formFields[fieldObj.keys].override === false
            ) {
              let array = formFields;
              if (fieldObj?.is_customizable) {
                array[fieldObj.keys] = {
                  value: event.data[1].data?.value,
                  override: false,
                  field_id: fieldObj?.field_id,
                  is_customizable: fieldObj?.is_customizable,
                };
              }
              else {
                array[fieldObj.keys] = {
                  value: event.data[1].data?.value,
                  override: false,
                };
              }
              setFormFields({ ...formFields, ...array });
            }
            const socketIndex = socketArray.findIndex(
              (x) => x.formula === event.data[0]
            );
            if (socketIndex !== -1) {
              socketArray.splice(socketIndex, 1);
            }
            if (socketArray.length > 0) {
              const fieldIndex = stepFields.findIndex(
                (x) => x.formula === socketArray[0].formula
              );
              if (fieldIndex !== -1) {
                let array = stepFields;
                array[fieldIndex].loader = true;
                setStepFields([...array]);
              }
              emitSocket(socketArray[0].formula, socketArray[0].data);
            }
          } else {
            console.log("Not Found", event);
          }
        }
      }
    });
  };

  const computeFields = (filteredFields, overrideEnabled) => {
    socketArray = [];

    setSocketArray(socketArray);
    if (overrideEnabled) {
      for (let index = 0; index < filteredFields.length; index++) {
        if (filteredFields[index].formula !== "") {
          socketArray.push({
            formula: filteredFields[index].formula,
            data: undefined,
          });
        }
      }
    } else {
      for (let index = 0; index < filteredFields.length; index++) {
        if (
          !socketArray.find((x) => x.formula === filteredFields[index].formula)
        ) {
          if (filteredFields[index].formula !== "") {
            socketArray.push({
              formula: filteredFields[index].formula,
              data: undefined,
            });
          }
        }
      }
    }
    if (socketArray.length > 0) {
      const fieldIndex = stepFields.findIndex(
        (x) => x.formula === socketArray[0].formula
      );
      if (fieldIndex !== -1) {
        let array = stepFields;
        array[fieldIndex].loader = true;
        setStepFields([...array]);
      }
      emitSocket(socketArray[0].formula, socketArray[0].data);
    }
  };

  const renderMaterialDD = (field) => {
    return (
      <select
        className="table-inputbox1 myselect normal"
        name={field.keys}
        onChange={(e) => {
          getFieldValues(e, true, false, field.field_data_type);
        }}
      >
        <option value="-1">Select {field.name}</option>
        {machine_options.length === 0
          ? renderOptions(field.data_reference)
          : ""}
        {machine_options.map((optionObj, index) => {


          return (
            <option
              value={optionObj.id}
              selected={
                formFields["machine_id"] &&
                optionObj.id === parseInt(formFields["machine_id"].value)
              }
            >
              {optionObj.name}
            </option>
          );
        })}
      </select>
    );
  };

  const renderMachineDD = (field) => {


    return (
      <select
        className="table-inputbox1 myselect normal"
        name={field.keys}
        onChange={(e) => {
          getFieldValues(e, true, false, field.field_data_type);
        }}
      >
        <option value="-1">Select {field.name}</option>
        {material_options.length === 0
          ? renderOptions(field.data_reference)
          : ""}
        {material_options.map((optionObj, index) => {

          return (
            <option
              value={optionObj.id}

              selected={
                formFields["material_id"] &&
                optionObj.id === parseInt(formFields["material_id"].value)
              }
            >
              {optionObj.name}
            </option>
          );
        })}
      </select>
    );
  };

  const renderParameterDD = (field) => {
    return (
      <select
        className="table-inputbox1 myselect normal"
        name={field.keys}
        onChange={(e) => {
          getFieldValues(e, true, false, field.field_data_type);
        }}
      >
        <option value="-1">Select {field.name}</option>
        {parameters_options.length === 0 && !fieldDataAvailability
          ? renderOptions(field.data_reference)
          : ""}
        {parameters_options.map((optionObj, index) => {
          return (
            <option
              value={optionObj.id}
              selected={
                formFields["parameter_id"] &&
                optionObj.id === parseInt(formFields["parameter_id"].value)
              }
            >
              {optionObj.name}
            </option>
          );
        })}
      </select>
    );
  };

  const renderFillRateDD = (field) => {
    return (
      <select
        className="table-inputbox1 myselect normal"
        name={field.keys}
        onChange={(e) => {
          getFieldValues(e, true, false, field.field_data_type);
        }}
      >
        <option value="-1">{field.name}</option>
        {fill_rate_options.length === 0
          ? renderOptions(field.data_reference)
          : ""}
        {fill_rate_options.map((optionObj, index) => {
          return (
            <option
              value={optionObj.id}
              selected={
                formFields["parameter_id"] &&
                optionObj.id === parseInt(formFields["parameter_id"].value)
              }
            >
              {optionObj.fill_rate_type}
            </option>
          );
        })}
      </select>
    );
  };

  const renderFilterDD = (field) => {
    return (
      <select
        className="table-inputbox1 myselect normal"
        name={field.keys}
        onChange={(e) => {
          getFieldValues(e, true, false, field.field_data_type);
        }}
      >
        <option value="-1">{field.name}</option>
        {filter_options.length === 0 ? renderOptions(field.data_reference) : ""}
        {filter_options.map((optionObj, index) => {
          return (
            <option
              value={optionObj.id}
              selected={
                formFields["filter_id"] &&
                optionObj.id === formFields["filter_id"].value
              }
            >
              {optionObj.name}
            </option>
          );
        })}
      </select>
    );
  };

  const renderRecoaterBladesDD = (field) => {
    return (
      <select
        className="table-inputbox1 myselect normal"
        name={field.keys}
        onChange={(e) => {
          getFieldValues(e, true, false, field.field_data_type);
        }}
      >
        <option value="-1">{field.name}</option>
        {recoater_blade_options.length === 0
          ? renderOptions(field.data_reference)
          : ""}
        {recoater_blade_options.map((optionObj, index) => {
          return (
            <option
              value={optionObj.id}
              selected={
                formFields["recoater_blade_id"] &&
                optionObj.id === formFields["recoater_blade_id"].value

              }
            >
              {optionObj.name}
            </option>
          );
        })}
      </select>
    );
  };

  const renderInertGasDD = (field) => {
    return (
      <select
        className="table-inputbox1 myselect normal"
        name={field.keys}
        onChange={(e) => {
          getFieldValues(e, true, false, field.field_data_type);
        }}
      >
        <option value="-1">{field.name}</option>
        {inert_gas_options.length === 0
          ? renderOptions(field.data_reference)
          : ""}
        {inert_gas_options.map((optionObj, index) => {
          return (
            <option
              value={optionObj.id}
              selected={
                formFields["select_inert_gas"] &&
                optionObj.id === formFields["select_inert_gas"].value
              }
            >
              {optionObj.name}
            </option>
          );
        })}
      </select>
    );
  };

  const renderBuildPlatformsDD = (field) => {
    // TODO
    return (
      <select
        className="table-inputbox1 myselect normal"
        name={field.keys}
        onChange={(e) => {
          getFieldValues(e, true, false, field.field_data_type);
        }}
      >
        <option value="-1">{field.name}</option>
        {build_platform_options.length === 0
          ? renderOptions(field.data_reference)
          : ""}
        {build_platform_options.map((optionObj, index) => {
          return (
            <option
              value={optionObj.id}
              selected={
                formFields["build_platform_id"] &&
                optionObj.id === formFields["build_platform_id"].value
              }
            >
              {optionObj.name}
            </option>
          );
        })}
      </select>
    );
  };

  const renderBatchFillingGrade = (field) => {
    // TODO
    return (
      <div>
        <select
          className="table-inputbox1 myselect normal"
          name={field.keys}
          onChange={(e) => {
            getFieldValues(e, true, false, field.field_data_type);
          }}
        >
          <option value="-1">{field.name}</option>
          <option value="80">Best (80%)</option>
          <option value="50">Regular (50%)</option>
          <option value="30">Worse (30%)</option>
        </select>
        <div onClick={() => setIsOpen(true)}>
          <img
            src="../assets/eye.png"
            alt=""
            height="18"
            width="27"
            className="no-hide batch-info"
          />
        </div>
      </div>
    );
  };

  const renderTooltip = (field) => {
    if (field.field_info !== "") {
      return (
        <div className="field tooltip-p">
          <img src="../assets/info.png" alt="" height="21" width="21" />
          <span className="tooltiptext">{field.field_info}</span>
        </div>
      );
    }
  };

  const handleBack = () => {
    if (fieldDataAvailability) {
      setFieldDataAvailability(false)
    }
    handlestep(--stepId);
  };

  const saveFormFieldsInDb = (formData) => {
    toggleMutation({
      variables: { calculations: formData },
      optimisticResponse: true,
    })
      .then((res) => { })
      .catch((error) => {
        console.log("Getting error from server");
      });
  };

  const saveCustomizedField = (field) => {
    if (!savingCustomizedFieldLoading) {
      mutateFunction({
        variables: { machine_id: parseInt(formFields["machine_id"].value), region_id: parseInt(formFields["region_id"].value), technology_id: technologyId, field_id: field?.field_id, calculator_type: calculatorType, value: parseFloat(formFields[field?.keys].value), material_id: formFields?.material_id?.value ? parseInt(formFields?.material_id?.value) : null },
        optimisticResponse: true,
      })
        .then((res) => { toast.success(RESPONSE_MESSAGES?.CUSTOMIZABLE_VALUE_SAVED) })
        .catch((error) => {
          console.log("Getting error from server");
        });
    }
  }
  const resetCustomizedField = (field) => {
    if (!resettingFieldLoading) {
      mutateResetCustomizedField({
        variables: { field_id: field?.field_id },
        optimisticResponse: true,
      })
        .then((res) => { toast.success(RESPONSE_MESSAGES?.VALUES_RESET_DEFAULT); openEditBox(field.field_id, false, field?.keys); })
        .catch((error) => {
          toast.error(RESPONSE_MESSAGES?.VALUES_ALREACY_DEFAULT)
        });
    }
  }
  const renderButtons = () => {
    const StepObj = techSteps.find((x) => x.step_id === stepId + 1);
    const StepObj3 = techSteps.find((x) => x.name === "Post Processing");

    if (StepObj) {
      const StepObj2 = techSteps.find((x) => x.step_id === stepId - 1);
      if (!StepObj2) {
        return (
          <>
            <td style={{ width: "50%" }}>
              <div id="btn-row">
                <a href="./region-select" className="button1">
                  <p>Back</p>
                </a>
              </div>
            </td>
            <td></td>
            <td>
              <div id="btn-row">
                <div className="button1" onClick={handleSection}>
                  <p>Next</p>
                </div>
              </div>
            </td>
          </>
        );
      } else {
        if (stepId === StepObj3.step_id) {
          return (
            <>
              <td>
                <div id="btn-row">
                  <div className="button1" onClick={handleBack}>
                    <p>Back</p>
                  </div>
                </div>
              </td>
              <td>
                {Access && (
                  <div id="btn-row">
                    <a
                      href="./cost-report"
                      className="button1"
                      onClick={(e) => {
                        sessionStorage.setItem("scenario_name", "default");
                        sessionStorage.setItem(
                          "calculator_values_default",
                          JSON.stringify(formFields)
                        );
                        sessionStorage.setItem("stepId", stepId)
                        saveFormFieldsInDb(formFields);
                      }}
                    >
                      <img
                        alt=""
                        src="../assets/report.png"
                        width="23"
                        height="24"
                      />
                      <p>Generate Report</p>
                    </a>
                  </div>
                )}
              </td>
              <td>
                <div id="btn-row">
                  <div className="button1" onClick={handleSection}>
                    <p>Next</p>
                  </div>
                </div>
              </td>
            </>
          );
        }
        else {
          return (
            <>
              <td style={{ width: "50%" }}>
                <div id="btn-row">
                  <div className="button1" onClick={handleBack}>
                    <p>Back</p>
                  </div>
                </div>
              </td>
              <td></td>
              <td>
                <div id="btn-row">
                  <div className="button1" onClick={handleSection}>
                    <p>Next</p>
                  </div>
                </div>
              </td>
            </>
          );
        }
      }
    } else {
      return (
        <>
          <td style={{ width: "50%" }}>
            <div id="btn-row">
              <div className="button1" onClick={handleBack}>
                <p>Back</p>
              </div>
            </div>
          </td>
          {/* TODO.... */}
          <td></td>
          <td>
            <div id="btn-row">
              <Link
                to="/calculator/visual-analytics/true"
                className="button1"
                onClick={(e) => {
                  socketObj.off("*");
                  const estimationVerticle = (sessionStorage.getItem("technologyName") === "Metal (Estimation)" || sessionStorage.getItem("technologyName") === "Polymer (Estimation)")
                  if (!estimationVerticle) {
                    const array = formFields
                    const totalAmCpp = parseFloat(parseFloat(formFields["system_cost_per_part"].value) + estimationVerticle ? 0 : parseFloat(formFields["recurring_cost_per_part"].value) + parseFloat(formFields["material_cost_per_part"].value) + estimationVerticle ? 0 : parseFloat(formFields["cpp_consumables"].value))
                    array["total_am_cpp"] = { value: totalAmCpp, override: false }
                    setFormFields({ ...formFields, ...array })
                  }
                  if (sessionStorage.getItem("calculator_values_names")) {
                    const calculator_values_multiple = JSON.parse(sessionStorage.getItem("calculator_values_names"))
                    if (calculator_values_multiple?.length) {
                      for (const item of calculator_values_multiple) {
                        sessionStorage.removeItem(`calculator_values_${item}`)
                      }
                      sessionStorage.removeItem(`calculator_values_multiple`)
                    }
                  }
                  sessionStorage.setItem("scenario_name", "default");
                  sessionStorage.setItem("stepId", stepId)
                  sessionStorage.setItem(
                    "calculator_values_default",
                    JSON.stringify(formFields)
                  );
                  saveFormFieldsInDb(formFields);
                  sessionStorage.removeItem("Analytics_Route")
                }}
              >
                <img
                  alt=""
                  src="../assets/analytics.png"
                  width="22"
                  height="22"
                />
                <p>Visual Analytics</p>
              </Link>
            </div>
          </td>
        </>
      );
    }
  };
  // const renderPermiumShow = () =>
  //   permiumField.map((item, index) => (
  //     <tr>
  //       <td className="permium-row" style={{ width: "100%" }}>
  //         {item.name}
  //         <a href="/premium-request" className="premium-row-text">
  //           Premium
  //         </a>
  //       </td>
  //       <td style={{ textAlign: "center" }}></td>
  //       <td></td>
  //     </tr>
  //   ));
  // TODO Code commented as not being used for now 

  const renderLoader = (field) => {
    // console.log("Field: ", field)
    if (field.loader) {
      return (
        <img className="small-loader" src={Spinner} alt="loader" height="10%" />
      );
    } else {
      if (!(!field.is_premium || Access)) {
        return;
      }

      let value = 0;
      if (formFields[field.keys] === undefined) {
        if (field.field_type === "constant" || field.keys === "labor_cost_per_hour") {
          let array = formFields;
          array[field.keys] = {
            value: parseFloat(field.data_reference),
            override: false,
          };

          setFormFields({ ...formFields, ...array });
        }
      } else {
        value = formFields[field.keys].value;
        if (field.name === "CPP Overhead & Consumables") {
          window.localStorage.setItem("CPP OC", formFields[field.keys].value);
          setOverHeadConsumables(formFields[field.keys].value);
        }
        // Field Name of each field to be used by user and value...
        // console.log("field check", field.name);
        // console.log("Value check", formFields[field.keys]);

        if (stepName === "System Cost") {
          checkArray1.push({
            name: field.name,
            value: formFields[field.keys].value,
          });
        }
        if (stepName === "Material Cost") {
          checkArray2.push({
            name: field.name,
            value: formFields[field.keys].value,
          });
        }
        if (stepName === "Post Processing") {
          checkArray3.push({
            name: field.name,
            value: formFields[field.keys].value,
          });
        }

        // if (stepName === "CPP Overhead & Consumables") {
        //   debugger
        // }

      }

      const subscript = renderSubscript(field.field_unit);

      if (field.field_unit.includes("currency")) {
        return (
          <p>
            {numberWithCommas(value)} {subscript}
          </p>
        );
      } else {
        return (
          <p>
            {value} {subscript}
          </p>
        );
      }
    }
  };

  const numberWithCommas = (x) => {
    if (x === 0) return 0;
    if (x) return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  };

  //Render the components on the base of key
  const renderComponents = () => {
    return (
      <div className="row p-0">
        <div className="col deadcenter">
          <div className="calcform calcform-wrapper">
            <table className="table calcform-top-btn">
              <tbody>
                <tr>{renderButtons()}</tr>
                <tr>
                  <td style={{ width: "50%" }}>
                    <div>
                      <p className="t-2 nudge">{stepName}</p>
                      <p className="s-1">{technologyName}</p>
                    </div>
                  </td>
                  <td></td>
                  <td>
                    {!Access && (
                      <a href="/premium-request">
                        <div id="btn-row mt-reset">
                          <div
                            className="text-center button-premium"
                            onClick={() => { }}
                          >
                            Click here to
                            <br />
                            <strong>
                              Access Premium now to unlock all features
                            </strong>
                          </div>
                        </div>
                      </a>
                    )}
                  </td>
                </tr>
              </tbody>
            </table>

            <table className="table ">
              <thead>
                <tr>
                  <th scope="col">Item</th>
                  <th scope="col"></th>
                  <th scope="col">Input</th>
                </tr>
              </thead>
              <tbody>
                {
                  // eslint-disable-next-line
                  stepFields.map((field, index) => {
                    // Total Render Fields for full simulation...
                    // console.log("field", field);
                    if (field.field_input_type === "dropdown") {


                      return (
                        <tr>
                          <td
                            className={
                              "" + (!field.access ? "permium-row" : "")
                            }
                            style={{ width: field.access ? "50%" : "100%" }}
                          >
                            <div className="field">
                              <p>{field.name}</p>
                              {!field.is_premium || Access ? (
                                renderTooltip(field)
                              ) : (
                                <a
                                  href="/premium-request"
                                  className="premium-row-text"
                                >
                                  Premium
                                </a>
                              )}
                            </div>
                          </td>

                          <td></td>
                          <td>
                            {(!field.is_premium || Access) && (
                              <>
                                <div className="vcenter">
                                  {field.data_reference === "Machine"
                                    ? renderMaterialDD(field)
                                    : ""}
                                  {field.data_reference === "Material"
                                    ? renderMachineDD(field)
                                    : ""}
                                  {field.data_reference === "Parameter" &&
                                    formFields["material_id"] !== undefined
                                    ? renderParameterDD(field)
                                    : ""}
                                  {field.data_reference ===
                                    "Parameter.fill_rate_type" &&
                                    formFields["material_id"] !== undefined &&
                                    formFields["parameter_name"] !== undefined
                                    ? renderFillRateDD(field)
                                    : ""}
                                  {field.data_reference === "Filters"
                                    ? renderFilterDD(field)
                                    : ""}
                                  {field.data_reference === "Recoater_Blade"
                                    ? renderRecoaterBladesDD(field)
                                    : ""}
                                  {field.data_reference === "Inert_Gas"
                                    ? renderInertGasDD(field)
                                    : ""}
                                  {field.data_reference === "Build_Platform"
                                    ? renderBuildPlatformsDD(field)
                                    : ""}
                                  {field.data_reference ===
                                    "Batch_Filling_Grade" &&
                                    field.renderer === 2
                                    ? renderBatchFillingGrade(field)
                                    : ""}
                                </div>
                                <span
                                  id={`error-${field.keys}`}
                                  className="error-messages"
                                >
                                  Please select {field.name}
                                </span>
                              </>
                            )}
                          </td>
                        </tr>
                      );
                    } else if (field.field_input_type === "label") {
                      return (
                        <>
                          <tr id={`row-spaceOne-${field.field_id}`} className="hidden" style={{ textAlign: "center" }}>
                            <td />
                            <td />
                            <td />
                          </tr>
                          <tr id={`row-spaceTwo-${field.field_id}`} className="hidden" style={{ textAlign: "center" }}>
                            <td />
                            <td />
                            <td />
                          </tr>
                          <tr id={`row-spaceThree-${field.field_id}`} className="hidden" style={{ textAlign: "center" }}>
                            <td />
                            <td />
                            <td />
                          </tr>
                          <tr>
                            <td
                              className={
                                "" + (!field.access ? "permium-row" : "")
                              }
                              style={{ width: field.access ? "50%" : "100%" }}
                            >
                              <div className="field">
                                <p>{field.name}</p>
                                {!field.is_premium || Access ? (
                                  renderTooltip(field)
                                ) : (
                                  <a
                                    href="/premium-request"
                                    className="premium-row-text"
                                  >
                                    Premium
                                  </a>
                                )}
                              </div>
                            </td>
                            <td style={{ textAlign: "center" }}>
                              {renderLoader(field)}
                            </td>
                            <td>
                              {(!field.is_premium || Access) && (
                                <div className="vcenter">
                                  {
                                    field?.keys === FIELD_NAMES?.RECURRING_EXPENSE
                                      ?
                                      <div
                                        id={`div-${field.field_id}`}
                                        className="edit-div table-inputbox2 hidden w-auto"
                                        style={{ flexDirection: 'column', height: "auto", gap: "10px" }}
                                      >
                                        {
                                          !!subFieldsByFieldId && subFieldsByFieldId.length > 0 ? (
                                            subFieldsByFieldId?.map((item, itemIndex) => {
                                              return (
                                                <div className="d-flex flex-column">
                                                  <p className="overrideable-input">{item?.name}</p>
                                                  <div
                                                    id={`div-${field.field_id}`}
                                                    className="edit-div table-inputbox2 mr-0"
                                                  >

                                                    <input
                                                      id={`input-${item.field_id}`}
                                                      type="text"
                                                      value={
                                                        formFields[item?.name?.toLowerCase()?.replace(" ", "_")] !== undefined &&
                                                          formFields[item?.name?.toLowerCase()?.replace(" ", "_")].override === true
                                                          ? formFields[item?.name?.toLowerCase()?.replace(" ", "_")].value
                                                          : ""
                                                      }
                                                      className="login-input"
                                                      defaultValue="0"
                                                      key={itemIndex + 10}
                                                      name={item?.name?.toLowerCase()?.replace(" ", "_")}
                                                      onChange={(e) => {
                                                        getFieldValues(
                                                          e,
                                                          true,
                                                          false,
                                                          item.field_data_type,
                                                          true
                                                        );
                                                      }}
                                                    />
                                                  </div>
                                                </div>
                                              )
                                            })
                                          )
                                            :
                                            (
                                              <img className="small-loader" src={Spinner} alt="loader" height="10%" />
                                            )
                                        }
                                      </div>

                                      :
                                      <div
                                        id={`div-${field.field_id}`}
                                        className={`${!!field?.is_customizable && "mr-0"}edit-div table-inputbox2 hidden`}
                                      >
                                        <input
                                          id={`input-${field.field_id}`}
                                          type="text"
                                          value={
                                            formFields[field.keys] !== undefined &&
                                              formFields[field.keys].override === true
                                              ? formFields[field.keys].value
                                              : ""
                                          }
                                          className="login-input"
                                          defaultValue="0"
                                          name={field.keys}
                                          onChange={(e) => {
                                            getFieldValues(
                                              e,
                                              true,
                                              false,
                                              field.field_data_type,
                                              true
                                            );
                                          }}
                                        />
                                      </div>
                                  }
                                  <div className="field tooltip-p" >
                                    <img
                                      id={`edit-${field.field_id}`}
                                      alt=""
                                      src="../assets/edit.png"
                                      width="23"
                                      height="23"
                                      onClick={() => {
                                        openEditBox(field.field_id, true, field?.keys, field?.keys);
                                      }}
                                      className={
                                        field.is_override_enabled === false
                                          ? "tooltip-p hidden editimg"
                                          : "tooltip-p editimg"
                                      }
                                    />
                                    <span className="tooltiptext">
                                      Click icon to add your desired value
                                      instead.{" "}
                                    </span>
                                  </div>
                                  {
                                    !!field?.is_customizable &&
                                    (
                                      <>
                                        <div className="field tooltip-p hidden" id={`save-${field.field_id}`} onClick={() => saveCustomizedField(field)}>
                                          <img
                                            alt=""
                                            src="../assets/save.png"
                                            width="23"
                                            height="23"
                                            onClick={() => {
                                              openEditBox(field.field_id, true, field?.keys);
                                            }}
                                            className={
                                              field.is_override_enabled === false
                                                ? "tooltip-p hidden editimg"
                                                : "tooltip-p editimg ml-0"
                                            }
                                          />
                                          <span className="tooltiptext customizable-field-tooltip">
                                            Click icon to save your value{" "}
                                          </span>
                                        </div>
                                        <div className="field tooltip-p hidden" id={`restore-${field.field_id}`} onClick={() => resetCustomizedField(field)}>
                                          <img
                                            alt=""
                                            src="../assets/restore.png"
                                            width="23"
                                            height="23"
                                            onClick={() => {
                                              openEditBox(field.field_id, true, field?.keys);
                                            }}
                                            className={
                                              field.is_override_enabled === false
                                                ? "tooltip-p hidden editimg"
                                                : "tooltip-p editimg ml-0"
                                            }
                                          />
                                          <span className="tooltiptext customizable-field-tooltip">
                                            Click to restore System value.{" "}
                                          </span>
                                        </div>
                                      </>
                                    )
                                  }

                                  <img
                                    id={`cross-${field.field_id}`}
                                    alt=""
                                    src="../assets/cross.png"
                                    width="24"
                                    height="24"
                                    onClick={() => {
                                      openEditBox(field.field_id, false, field?.keys);
                                    }}
                                    className="hidden crossimg"
                                  />
                                </div>
                              )}
                            </td>
                          </tr>
                          <tr id={`row-spaceFour-${field.field_id}`} className="hidden" style={{ textAlign: "center" }}>
                            <td />
                            <td />
                            <td />
                          </tr>
                          <tr id={`row-spaceFive-${field.field_id}`} className="hidden" style={{ textAlign: "center" }}>
                            <td />
                            <td />
                            <td />
                          </tr>
                          <tr id={`row-spaceSix-${field.field_id}`} className="hidden" style={{ textAlign: "center" }}>
                            <td />
                            <td />
                            <td />
                          </tr>
                        </>
                      );
                    } else if (field.field_input_type === "multiple_labels") {
                      return (
                        <tr>
                          <td
                            className={
                              "" + (!field.access ? "permium-row" : "")
                            }
                            style={{ width: field.access ? "50%" : "100%" }}
                          >
                            <div className="field">
                              <p>{field.name}</p>
                              {!field.is_premium || Access ? (
                                renderTooltip(field)
                              ) : (
                                <a
                                  href="/premium-request"
                                  className="premium-row-text"
                                >
                                  Premium
                                </a>
                              )}
                            </div>
                          </td>
                          <td style={{ textAlign: "center" }}>
                            {renderLoader(field)}
                          </td>
                          <td>
                            {(!field.is_premium || Access) && (
                              <div className="vcenter">
                                <div
                                  id={`div-${field.field_id}`}
                                  className="edit-div table-inputbox2 hidden"
                                >
                                  <input
                                    id={`input-${field.field_id}`}
                                    type="text"
                                    value={
                                      formFields[field.keys] !== undefined &&
                                        formFields[field.keys].override === true
                                        ? formFields[field.keys].value
                                        : ""
                                    }
                                    className="login-input"
                                    defaultValue="0"
                                    name={field.keys}
                                    onChange={(e) => {
                                      getFieldValues(
                                        e,
                                        true,
                                        false,
                                        field.field_data_type,
                                        true
                                      );
                                    }}
                                  />
                                </div>
                                <div className="field tooltip-p" >
                                  <img
                                    id={`edit-${field.field_id}`}
                                    alt=""
                                    src="../assets/edit.png"
                                    width="23"
                                    height="23"
                                    onClick={() => {
                                      openEditBox(field.field_id, true, field?.keys);
                                    }}
                                    className={
                                      field.is_override_enabled === false
                                        ? "hidden editimg"
                                        : "editimg"
                                    }
                                  />
                                </div>
                                <img
                                  id={`cross-${field.field_id}`}
                                  alt=""
                                  src="../assets/cross.png"
                                  width="24"
                                  height="24"
                                  onClick={() => {
                                    openEditBox(field.field_id, false, field?.keys);
                                  }}
                                  className="hidden crossimg"
                                />
                              </div>
                            )}
                          </td>
                        </tr>
                      );
                    } else if (field.field_input_type === "textfield") {
                      return (
                        <tr>
                          <td
                            className={
                              "" + (!field.access ? "permium-row" : "")
                            }
                            style={{ width: field.access ? "50%" : "100%" }}
                          >
                            <div className="field">
                              <p
                                className={
                                  field.name === "z" && "margin-bottom-xp"
                                }
                              >
                                {field.name}
                              </p>
                              {!field.is_premium || Access ? (
                                renderTooltip(field)
                              ) : (
                                <a
                                  href="/premium-request"
                                  className="premium-row-text"
                                >
                                  Premium
                                </a>
                              )}
                            </div>
                          </td>

                          <td></td>
                          <td>
                            {(!field.is_premium || Access) && (
                              <>
                                <div
                                  data-tip={field.field_info}
                                  className="vcenter"
                                >
                                  <div className="table-inputbox">
                                    <input
                                      type="text"
                                      className="login-input"
                                      name={field.keys}
                                      value={
                                        formFields[field.keys] === undefined
                                          ? ""
                                          : (field.keys ===
                                            "cost_of_power_per_kw" ||
                                            field.keys ===
                                            "cost_of_floor_space_per_year" ||
                                            field.keys ===
                                            "additional_material_losses" ||
                                            field.keys === "scrap_rate" ||
                                            field.keys === "support_factor" ||
                                            field.keys ===
                                            "additional_material_losses") &&
                                            formFields[field.keys].value === 0
                                            ? ""
                                            : formFields[field.keys].value
                                      }
                                      onChange={(e) => {
                                        field.keys === "part_volume" ||
                                          field.keys === "part_support_volume" ||
                                          field.keys === "no_of_parts"
                                          ? getFieldValues(
                                            e,
                                            true,
                                            true,
                                            field.field_data_type,
                                            false,
                                            true
                                          )
                                          : getFieldValues(
                                            e,
                                            true,
                                            false,
                                            field.field_data_type,
                                            false,
                                            false,
                                            true
                                          );
                                      }}
                                    />
                                    {renderSubscript(field.field_unit)}
                                  </div>
                                </div>
                                <span
                                  id={`error-${field.keys}`}
                                  className="error-messages"
                                >
                                  {field.name} is Required
                                </span>
                              </>
                            )}
                          </td>
                        </tr>
                      );
                    } else if (field.field_input_type === "label_section") {
                      return (
                        <tr className="hr">
                          <td
                            className={
                              "heading-bolder" +
                              (!field.access ? "permium-row" : "")
                            }
                            style={{ width: field.access ? "50%" : "100%" }}
                          >
                            <div className="field margin-top-xp-bold">
                              <p>{field.name}</p>
                              {!field.is_premium || Access ? (
                                renderTooltip(field)
                              ) : (
                                <a
                                  href={CONDITIONALROUTES.PREMIUM_REQUEST}
                                  className="premium-row-text"
                                >
                                  Premium
                                </a>
                              )}
                            </div>
                          </td>
                          <td></td>
                          <td></td>
                        </tr>
                      );
                    } else if (
                      field.field_input_type === "enable_multiple_rows"
                    ) {
                      return (
                        <>
                          {renderRetroJobs()}
                          <tr className="upper-hr">
                            <td style={{ width: "50%" }}>
                              <div
                                id="add-more"
                                onClick={() =>
                                  addMoreJobs(technologyName, index)
                                }
                              >
                                <img
                                  alt=""
                                  src="../assets/add.png"
                                  height="24"
                                  width="24"
                                />
                                <p>Add more for heterogeneous job</p>
                                {!field.is_premium || Access ? (
                                  renderTooltip(field)
                                ) : (
                                  <a
                                    href="/premium-request"
                                    className="premium-row-text"
                                  >
                                    Premium
                                  </a>
                                )}
                              </div>
                            </td>
                            <td></td>
                            <td></td>
                          </tr>
                        </>
                      );
                    }
                  })
                }
                {/* {
                                    !Access && renderPermiumShow()
                                } */}
                <tr>{renderButtons()}</tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    );
  };

  const renderFields = () => {
    if (fieldsLoader) {
      return (
        <>
          <div
            className="row pad-50 fill"
            id="map"
            style={{ marginTop: "15%" }}
          >
            <div className="col p-0 deadcenter ">
              <img
                className="mt-n5"
                src={Spinner}
                alt="loader"
                height="100px"
              />
            </div>
          </div>
        </>
      );
    } else {
      return <>{renderComponents()}</>;
    }
  };

  return (
    <React.Fragment>
      <ToastContainer />
      <Navbar />
      <Banner />

      {loading ? (
        <>
          <div className="row p-0">
            <div className="col p-0">
              <div className="container-fluid bar">
                <div className="row progress-holder">{renderSteps()}</div>
              </div>
            </div>
          </div>
          {renderFields()}
          <ReactTooltip />
        </>
      ) : (
        <div className="row pad-50 fill" id="map">
          <div className="col p-0 deadcenter ">
            <img className="mt-n5" src={Spinner} alt="loader" height="100px" />
          </div>
        </div>
      )}
      {isOpen && <BatchFillingPop isOpen={isOpen} setIsOpen={setIsOpen} />}
    </React.Fragment>
  );
};

export default CalculatorHeader;
